import React, { useEffect, useState, useRef } from "react";
import { Anchor, Animation } from '@/components'
import { Tooltip } from 'antd';
import classnames from "classnames";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import bg from "@/assets/icons/bg.png";
import team from "@/assets/icons/team.png";
import team1 from "@/assets/icons/team1.png";
import team2 from "@/assets/icons/team2.png";
import teamIcon from "@/assets/icons/teamIcon.png";
import teamIcon2 from "@/assets/icons/teamIcon2.png";
import teamCap from "@/assets/icons/teamCap.png";
import teamUser from "@/assets/icons/teamUser.png";
import serviceContent from "@/assets/icons/serviceContent.png";
import challenge from "@/assets/icons/challenge.png";
import ill1 from "@/assets/icons/ill1.png";
import ill2 from "@/assets/icons/ill2.png";
import ill3 from "@/assets/icons/ill3.png";
import ill4 from "@/assets/icons/ill4.png";
import professional from "@/assets/icons/professional.png";
import serviceContentIcon1 from "@/assets/icons/serviceContentIcon1.png";
import serviceContentIcon2 from "@/assets/icons/serviceContentIcon2.png";
import serviceContentIcon3 from "@/assets/icons/serviceContentIcon3.png";
import suc1 from "@/assets/icons/suc1.png";
import suc2 from "@/assets/icons/suc2.png";
import suc3 from "@/assets/icons/suc3.png";
import dataS from "@/assets/icons/dataS.png";
import applicationBg1 from "@/assets/icons/applicationBg1.png";
import applicationBg2 from "@/assets/icons/applicationBg2.png";
import applicationBg3 from "@/assets/icons/applicationBg3.png";
import applicationBg4 from "@/assets/icons/applicationBg4.png";
import appIcon1 from "@/assets/icons/appIcon1.png";
import appIcon2 from "@/assets/icons/appIcon2.png";
import appIcon3 from "@/assets/icons/appIcon3.png";
import appIcon4 from "@/assets/icons/appIcon4.png";
import appIconBlue1 from "@/assets/icons/appIconBlue1.png";
import appIconBlue2 from "@/assets/icons/appIconBlue2.png";
import appIconBlue3 from "@/assets/icons/appIconBlue3.png";
import appIconBlue4 from "@/assets/icons/appIconBlue4.png";
import styles from "./index.module.scss";

const { Animation1, Animation2, Animation3, Animation4, Animation5 } = Animation;
// 首页
const Home = (props) => {
  const { history } = props;
  const styleRef1 = useRef();
  const styleRef2 = useRef();
  const styleRef3 = useRef();
  const styleRef4 = useRef();
  const styleRef5 = useRef();

  const [checkedTab, setCheckedTab] = useState(0);

  // 初始加载
  useEffect(() => { }, []);

  const handleClick = (link) => {
    console.log(link);
  };
  const handleChange = (link) => {
    console.log(link);
  };
  const handleGo = (href) => {
    href && window.open(href);
  };
  const applicationArr = [
    {
      showName: "数据中心建设维护",
      showLogo: appIcon1,
      hideName: "数据中心建设维护",
      hideLogo: appIconBlue1,
      isChild:false,
      bg:applicationBg1,
      desc: "对公安网、视频专网、互联网、档案影像化系统等多网多系统数据的梳理融合，创建公安交管大数据中心；通过对公交、出租、网约车、停车场、航空、铁路、汽车维修、旅游景区等10+行业管理部门、40+业务系统数据的数据接入，创建交通运输数据中心。",
    },
    {
      showName: "业务难点算法应用",
      showLogo: appIcon2,
      hideName: "业务难点算法应用",
      hideLogo: appIconBlue2,
      isChild:false,
      bg:applicationBg2,
      desc: "基于大数据算法和AI技术，研发了非法营运车识别模型、校车/公交线路规划模型、出租车异常聚集模型、重要场站运力调度模型、节假日流量预测模型、AI算法辅助违法判罚模型、AI收银稽核等50+算法模型。",
    },
    {
      showName: "数据资产梳理沉淀",
      showLogo: appIcon3,
      hideName: "数据资产梳理沉淀",
      hideLogo: appIconBlue3,
      isChild:false,
      bg:applicationBg3,
      desc: "通过对数据的持续挖掘、分析，结合13类国家标准、省级标准和其他7600条通用标准，整理交通全行业的3700余张表30000余个数据元，涵盖了21个行业领域，一共构建了140个小类，输出1385项指标的交通行业指标体系。",
    },
    {
      showName: "业务运营保障提升",
      showLogo: appIcon4,
      hideName: "业务运营保障提升",
      hideLogo: appIconBlue4,
      isChild: true,
      bg: applicationBg4,
      children: [
        "针对交通管理局、交通运输局所关注的问题痛点， 结合项目发展需求，为业主提供城市地铁公交接驳运营优化报告、城市路网运输效力分析报告、城市道路错峰出行方案设计、城市路网运行分析报告、基于城市交通状况对节假日出行提示报告等40+份相关报告，深耕数据运营，释放数据价值。",
        "交管每日办理机动车、每日驾驶证业务量，每日驾考（科目1/2/3/4）人数，违法、事故发生数量，公交车、出租车、网约车、轨道交通每日客运人数，每日城市进出车流量，每日航空、铁路、公路的客运量，卡口过车数等数据量的数据统计能力。",
        "针对交通运输行业，在营运车辆安全监管中，研发并落地了70+个风险隐患监管模型。",
        "针对交通管理行业，研发并落地了160+个创新业务监管点。",
      ],
    },
  ];
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className={styles.homeBox}>
      <div className={styles.banner}>
        <div className={styles.bannerBox} style={{ backgroundImage: `url(${dataS})` }}>
          <div className={styles.content}>数据服务</div>
          <div className={styles.content2}>以数据为核心，提供数据采集、治理、分析、计算和创新应用的综合能力。</div>
        </div>
      </div>
      <div className={styles.product}>
        <Anchor onChange={handleChange} onClick={handleClick} data={[
          {
            key: 'part-1',
            href: '#part-1',
            title: '服务价值',
          },
          {
            key: 'part-2',
            href: '#part-2',
            title: '服务内容',
          },
          {
            key: 'part-3',
            href: '#part-3',
            title: '应用场景',
          },
          {
            key: 'part-4',
            href: '#part-4',
            title: '专业团队',
          },
          {
            key: 'part-5',
            href: '#part-5',
            title: '成功案例',
          },
        ]} />
        <div id="part-1" className={styles.cardBox} style={{ backgroundImage: `url(${bg})` }}>
          <div className={styles.title}>服务价值</div>
          <div className={styles.challengeBox}>
            <div className={styles.challenge} ref={styleRef1}>
              <div className={styles.animationIcon}><Animation3 element={styleRef1} /></div>
              {/* <img src={challenge}></img> */}
              <div className={styles.challengeContent}>统筹数据管理，打破数据壁垒</div>
              <div className={styles.challengeContentLine}></div>
              <div className={styles.challengeContent2}>依托大数据基础平台，对交通行业数据进行统筹管理，通过数据资产梳理，提升数据使用效能。</div>
            </div>
            <div className={styles.challenge} ref={styleRef2} style={{ backgroundColor: '#FCFDFF' }}>
              <div className={styles.animationIcon}><Animation5 element={styleRef2} /></div>
              {/* <img src={challenge}></img> */}
              <div className={styles.challengeContent}>统一数据标准，促进数据共享</div>
              <div className={styles.challengeContentLine}></div>
              <div className={styles.challengeContent2}>通过建立统一的交通运输数据标准，规范数据接入、管理及共享流程，推动数据共享建设。</div>
            </div>
            <div className={styles.challenge} ref={styleRef3}>
              <div className={styles.animationIcon}><Animation4 element={styleRef3} /></div>
              {/* <img src={challenge}></img> */}
              <div className={styles.challengeContent}>发挥数据价值，提高监管效率</div>
              <div className={styles.challengeContentLine}></div>
              <div className={styles.challengeContent2}>通过提供数据汇聚治理、统计分析、价值挖掘及业务运营多种维度的数据服务内容，激活数据价值，实现数据赋能业务的完整闭环。</div>
            </div>
          </div>
        </div>
        <div id="part-2" className={classnames({[styles.cardBox]: true})} style={{ paddingBottom: '48px', backgroundColor: '#FFFFFF' }}>
          <div className={styles.title}>服务内容</div>
          <div className={styles.serviceType}>
            <div className={classnames({[styles.serviceTypeItem]: true, [styles.serviceTypeItem1]: true})}>
              <div className={styles.left}><img src={ill1} /></div>
              <div className={styles.right}>
                <div className={styles.content1}>数据汇聚治理</div>
                <div className={styles.content2}>依托数据资源管理平台（Data S），完成实时、离线、结构化、非结构化与半结构化、文件与数据日志等数据采集工作，实现不同源数据融合、解决数据孤岛问题。同事提供元数据管理、数据血缘、主数据管理、数据生命周期管理、数据标准、数据质量、数据安全、数据共享等服务，做好数据的治理与保障。</div>
              </div>
            </div>
            <div className={classnames({[styles.serviceTypeItem]: true, [styles.serviceTypeItem2]: true})}>
              <div className={styles.left}>
                <div className={styles.content1}>数据统计分析</div>
                <div className={styles.content2}>基于数据可视化平台（DataA），使用数据统计、构建指标体系、搭建用户画像体系等技术手段和工具。以业务需求为导向，从数据出发，进行数据的统计分析，为业务分析决策提供数据支撑。</div>
              </div>
              <div className={styles.right}><img src={ill2} /></div>
            </div>
            <div className={classnames({[styles.serviceTypeItem]: true, [styles.serviceTypeItem1]: true})}>
              <div className={styles.left}><img src={ill3} /></div>
              <div className={styles.right}>
                <div className={styles.content1}>数据价值挖掘</div>
                <div className={styles.content2}>基于数据挖掘平台（Data M），根据用户的业务场景和业务痛点，使用机器学习、神经网络、图像识别、评分卡、NLP、知识图谱等大数据技术，挖掘数据价值，解决业务痛难点</div>
              </div>
            </div>
            <div className={classnames({[styles.serviceTypeItem]: true, [styles.serviceTypeItem2]: true})}>
              <div className={styles.left}>
                <div className={styles.content1}>数据业务运营</div>
                <div className={styles.content2}>基于交通管理局相关业务办理情况、交通运输局各行业运营情况，挖掘数据价值及规律，结合相关要求，输出违规选取吉祥号、节假日客流量预测等数十份数据报告</div>
              </div>
              <div className={styles.right}><img src={ill4} /></div>
            </div>
          </div>
        </div>
        <div
          id="part-3"
          className={styles.cardBox}
          style={{ backgroundColor: "#FFFFFF" }}
        >
          <div className={styles.title}>应用场景</div>
          <div className={styles.applicationBox}>
            {applicationArr.map((v, i) => (
              <div className={styles.appItem} key={i}>
                <img className={styles.bg} src={v.bg}></img>
                <div className={styles.showBox}>
                  <img className={styles.logo} src={v.showLogo}></img>
                  <div className={styles.title}> {v.showName}</div>
                </div>
                <div
                  className={styles.hideBox}
                  style={{ display: !v.isChild && "flex" }}
                >
                  {v.isChild ? (
                    <Slider
                      {...settings}
                      className="swiper-container"
                      style={{ with: "100%" }}
                    >
                      {v.children.map((val, index) => (
                        <div key={index} className={styles.sliderItem}>
                          <img className={styles.logo} src={v.hideLogo} />
                          <div className={styles.title}> {v.hideName}</div>
                          <div className={styles.desc}>{val}</div>
                        </div>
                      ))}
                    </Slider>
                  ) : (
                    <div>
                      <img className={styles.logo} src={v.hideLogo} />
                      <div className={styles.title}> {v.hideName}</div>
                      <div className={styles.desc}>{v.desc}</div>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div
          id="part-4"
          className={styles.cardBox}
          style={{ backgroundImage: `url(${team})` }}
        >
          <div className={styles.title} style={{ color: "#FFFFFF" }}>
            专业团队
          </div>
          <div className={styles.proImgBox}>
            <div className={styles.proImg1}>
              <img src={professional} />
            </div>
            <div className={styles.proContent}>
              {/* 智诚科技拥有大数据开发工程师、大数据分析工程师、大数据挖掘工程师、大数据架构师、高级数据仓库工程师、图像算法工程师等多样化的专业大数据人才，深入挖掘、分析交通数据价值。 */}
              <div className={styles.proItem}>
                <img src={team1} />
                <div className={styles.itemCon}>
                  <div className={styles.conTitle}>
                    <img src={teamIcon} />
                    自有队伍
                  </div>
                  <div className={styles.tAleft}>公司拥有：</div>
                  <div className={classnames(styles.conRow,styles.w148Box)}>
                    <div className={styles.conCol}>
                      <img src={teamUser} />
                      大数据开发工程师
                    </div>
                    <div className={styles.conCol}>
                      <img src={teamUser} />
                      大数据分析工程师
                    </div>
                    <div className={styles.conCol}>
                      <img src={teamUser} />
                      大数据挖掘工程师
                    </div>
                  </div>
                  <div className={classnames(styles.conRow,styles.w148Box)}>
                    <div className={styles.conCol}>
                      <img src={teamUser} />
                      图像算法工程师
                    </div>
                    <div className={styles.conCol}>
                      <img src={teamUser} />
                      交通业务产品经理
                    </div>
                    <div className={styles.conCol}>
                      <img src={teamUser} />
                      大数据运营工程师
                    </div>
                  </div>
                  <div className={styles.conDesc}>等专业技术人才提供交通领域专业化数据服务。</div>
                </div>
              </div>
              <div className={styles.line}></div>
              <div className={styles.proItem}>
              <div className={styles.itemCon}>
                  <div className={styles.conTitle}>
                    <img src={teamIcon2} />
                    创新合作
                  </div>
                  <div className={styles.tAleft}>合作高校包括：</div>
                  <div className={styles.conRow}>
                    <div className={styles.conCol}>
                      <img src={teamCap} />
                      清华大学
                    </div>
                    <div className={styles.conCol}>
                      <img src={teamCap} />
                      上海交通大学
                    </div>
                    <div className={styles.conCol}>
                      <img src={teamCap} />
                      长安大学
                    </div>
                    <div className={styles.conCol}>
                      <img src={teamCap} />
                      长沙理工大学
                    </div>
                  </div>
                  <div className={styles.conRow}>
                    <div className={styles.conCol}>
                      <img src={teamCap} />
                      贵州大学
                    </div>
                    <div className={styles.conCol}>
                      <img src={teamCap} />
                      贵州理工大学
                    </div>
                    <div className={styles.conCol}  style={{width:'224px'}}>
                      <img src={teamCap} />
                      贵州交通职业技术学院
                    </div>
                  </div>
                  <div className={styles.conDesc}>与清华大学、上海交通大学等高校合作了<span style={{color:'rgba(226, 208, 143, 1)'}}>AI算法辅助违法判罚、AI算法辅助收银视频监控、公交线网优化、车辆事故风险预测、城市错峰出行</span>等交通领域算法课题研究，持续探索大数据算法应用于交通领域产品创新，不断推动研究成果转化到实际场景应用。</div>
                </div>
                <img src={team2} />
              </div>
            </div>
          </div>
        </div>
        
        <div id="part-5" className={styles.cardBox} style={{ backgroundColor: '#FFFFFF' }}>
          <div className={styles.title}>成功案例</div>
          {/* <div className={styles.titleSc} style={{ marginTop: '4px' }}>通过大数据+交通的深度融合，构建联通政府、企业及公众三端数据融合的全域交通产业生态</div> */}
          <div className={styles.sucBox}>
            <div className={styles.sucItem} onClick={() => history.push('/home/caseDetail?sort=5')}>
              <img src={suc1} />
              <div className={styles.sucContent}>贵阳市城市智慧交通平台</div>
              <div className={styles.message}>
                <div className={styles.title}><Tooltip title="贵阳市城市智慧交通平台">贵阳市城市智慧交通平台</Tooltip></div>
                <div className={styles.text}>2020年，贵阳市城市智慧交通平台在贵阳市上线运行，接入了全市公交、出租、网约车、停车场、航空、铁路、汽车维修、旅游景区等10+行业管理部门、40+业务系统数据，累计接入数据超过734.3亿条，构建了全市综合交通的数据中心。</div>
                <div className={styles.link}>
                  {"查看详情  >"}
                </div>
              </div>
            </div>
            <div className={styles.sucItem} onClick={() => history.push('/home/caseDetail?sort=6')}>
              <img src={suc2} />
              <div className={styles.sucContent}>贵阳市交管数据中心</div>
              <div className={styles.message}>
                <div className={styles.title}><Tooltip title="贵阳市交管数据中心">贵阳市交管数据中心</Tooltip></div>
                <div className={styles.text}>2015年， 贵阳市交管数据中心在贵阳贵安上线运行，通过对公安交通管理综合应用平台、公安交通集成指挥平台、区智能交通综合管控平台等平台的数据汇聚融合，完成对900多亿条卡口数据、5亿多条违法及相关数据、5.9亿多条业务办理数据、0.104亿多条事故及相关数据、0.82亿多条驾考及相关业务数据汇聚、治理、分析、存储和共享，打破数据孤岛，形成支撑贵阳市交警实战化应用的统一交通数据资源管理中心。</div>
                <div className={styles.link}>
                  {"查看详情  >"}
                </div>
              </div>
            </div>
            <div className={styles.sucItem} onClick={() => history.push('/home/caseDetail?sort=7')}>
              <img src={suc3} />
              <div className={styles.sucContent}>新疆交警总队公安交管大数据研判平台</div>
              <div className={styles.message}>
                <div className={styles.title}><Tooltip title="新疆交警总队公安交管大数据研判平台">新疆交警总队公安交管大数据研判平台</Tooltip></div>
                <div className={styles.text}>2019年，新疆公安交管大数据研判平台上线运行，平台完成了对公安网、视频专网、互联网、档案影像化系统等多往数据的梳理融合，并接入六合一业务数据71亿条（2344.7G）、集成指挥数据96亿条（2056G）、交通安全综合服务（12123系统）业务数据2194万条（187G）、农村道路系统数据48万条（156G），完成12个业务系统，3608张数据表的数据接入和标准化治理。</div>
                <div className={styles.link}>
                  {"查看详情  >"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
