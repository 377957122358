import axios, { CancelToken } from 'axios';
import { message } from 'antd';
import {envName, requestUrl} from './config';
import { history } from './index';
/**
 * 请求
 */
const AUTH_TOKEN = 'authToken';
const baseURL = requestUrl;

const ERROR_TYPES = {
    TOKEN_INVALID_1: 401,
    TOKEN_INVALID_2: 2003,
};

let requestError = false;

export class AxiosCancelablePromise extends Promise {
    constructor(excu, cancelToken) {
        super(excu);
        this.cancelToken = cancelToken;
    }

    /**
     * 中断当前请求，但是不会抛出任何错误
     */
    cancel() {
        this.cancelToken.cancel();
    }
}

class HttpRequest {
    constructor(baseUrl = baseURL) {
        this.baseUrl = baseUrl;
    }

    // /内置请求参数
    getInsideConfig() {
        const config = {
            baseURL: this.baseUrl,
            timeout: 500000,
            headers: {},
        };
        return config
    }

    handleError(error){
        const message = ((error.message === 'Network Error')?'服务器内部错误!':error.message) || '服务器内部错误'
        if(!error.code) return message
    }

    errVerifyFunc(code) {
        switch (code) {
            case ERROR_TYPES['TOKEN_INVALID_1']:
            case ERROR_TYPES['TOKEN_INVALID_2']: {
                const backUrl = window.location.hash.split('#')[1];
                history.replace(`/?back_url=${encodeURIComponent(backUrl)}`);
                if (!requestError) requestError = true;
                break;
            }
            default: {
                requestError = false;
                break;
            }
        }
    };

    /**
     * 拦截器
     * @param {object} instance 请求实例
     * @param {string} url 请求路径
     * @param {boolean} withToken 请求是否需要携带token
     */
    interceptors(instance, url, withToken = true) {
        // 请求拦截
        instance.interceptors.request.use(function(config){
            if (withToken) {
                config.headers = {
                    ...config.headers,
                    'token': localStorage.getItem([AUTH_TOKEN])
                }
            }
            if (envName === 'guiyangPublic') {
                config.headers['zcreate-netType'] = 'outterNet';
            }
            if (envName === 'guiyangRelease') {
                config.headers['zcreate-netType'] = 'innerNet';
            }
            if (envName === 'SECQaRelease') {
                config.headers['zcreate-netType'] = 'outterNet';
            }
            return config
        }, (error) => {
            return Promise.reject(error)
        })
        // 响应拦截
        instance.interceptors.response.use( async (res) => {
            if (!res) throw new Error('服务器内部错误');
            if(res.data instanceof Blob){
                return res;
            }
            if (res.data) {
                let result = res.data || {};
                if (result.code * 1 !== 200) {
                    await this.errVerifyFunc(result.code);
                    if (requestError) result.notShowMsg = true;
                } else {
                    result.notShowMsg = false;
                }
                return result;
            }
            return res
        }, (error) => {
            const errorMsg = error.message
            const response = (error.response || {}).data || {}
            const msg = response.msg || response.message ||errorMsg || '服务器内服错误'
            return Promise.reject(new Error(msg))
        })
    }

    /**
     * 发起请求
     * @param {object} options 请求配置
     * @param {string} options.url 请求地址
     * @param {'GET'|'POST'} options.method 请求方法
     * @param {object} options.headers 请求头
     * @param {string} options.headers.Authorization
     * @param {Array<function>} options.transformRequest
     * @param {boolean} withToken 此接口是否需要权限
     * @param {boolean} showMsg 是显示服务端返回的信息
     * @return {Promise} 返回的为promise类型
     */
    request(options, withToken = true, showMsg = false) {
        const instance = axios.create();
        options = Object.assign(this.getInsideConfig(), options);
        this.interceptors(instance, options.url, withToken)
        const cancelToken = CancelToken.source();
        return new AxiosCancelablePromise((resolve, reject) => {
            instance(options).then((res) => {
                if(res && res.code * 1 === 200){
                    if(showMsg) message.info(res.msg,2)
                    resolve(res);
                }else{
                    if (!res.notShowMsg) message.error(res.msg,2)
                    reject();
                }
            }).catch(({error, notShowError}) => {
                // reject();
            })
        }, cancelToken);
    }
}

let baseUrl = baseURL;

export {
    baseUrl
}
export default new HttpRequest(baseUrl)
