/**
 * store主入口主要处理store的全局配置及输出store
 *
 * */

import { init } from '@rematch/core';
import common from './models';
import createLoadingPlugin from '@rematch/loading';
import modular from '@/utils/importStore'  // 搜索views下面store

const loadingPlugin = createLoadingPlugin({});

const models = Object.assign(common, modular);

const store = init({
  models,
  plugins: [loadingPlugin],
});
export default store;
