import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { Tabs } from 'antd';


// Course 企业历程
const Course = (props) => {
    const {
        data = [],
        showKey,
        onClick = () => {},
    } = props;
    const [activeKey, setActiveKey] = useState(0);

    // 初始加载
    useEffect(() => {
        if (showKey) setActiveKey(showKey);
    }, [showKey]);


    const handleActiveKey = (key) => {
        setActiveKey(key);
        onClick(key)
    };

    return (
        <div className={styles.course}>
            <div className={styles.stage}>
                {
                    (data || []).map((item, idx) => (
                        <div className={styles.item} key={idx}>
                            {
                                idx < data.length - 1 &&
                                <div className={styles.line}></div>
                            }
                            <div className={styles.point} onClick={() => handleActiveKey(idx)}>
                                {
                                    activeKey === idx && <div className={styles.selected}></div>
                                }
                            </div>
                            <div className={styles.title}>{item.date}</div>
                        </div>
                    ))
                }
            </div>
            <div className={styles.content}>
                <div className={styles.title}>
                    <div>{data[activeKey].title}</div><div className={styles.line}></div>
                </div>
                <div className={styles.desc}>
                    {
                        (data[activeKey].keynote || []).map((item, idx) => (
                            <p key={idx}>{item}</p>
                        ))
                    }
                </div>
            </div>
        </div>
    )
};

export default Course;
