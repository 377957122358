import React, {useEffect, useRef, useState} from "react";
import {Anchor, Animation} from '@/components'
import AppScene from '../components/AppScene'
import styles from "./index.module.scss";
import banner from 'assets/core/banner2.jpg';
import scene5 from '@/assets/core/scene6.png';
import scene6 from '@/assets/core/scene5.png';
import scene7 from '@/assets/core/scene7.png';
import scene8 from '@/assets/core/scene8.png';
import scene9 from '@/assets/core/scene9.png';
import frame2 from 'assets/core/framework2.png';
import func1 from '@/assets/core/vehicle_func1.png';
import func2 from '@/assets/core/vehicle_func2.png';
import func3 from '@/assets/core/vehicle_func3.png';
import func4 from '@/assets/core/vehicle_func4.png';
import func5 from '@/assets/core/vehicle_func5.png';
import func6 from '@/assets/core/vehicle_func6.png';
import func7 from '@/assets/core/vehicle_func7.png';
import func8 from '@/assets/core/vehicle_func8.png';
import func9 from '@/assets/core/vehicle_func9.png';

const { Animation1, Animation2, Animation3, Animation5 } = Animation;

// Vehicle 营运车
const Vehicle = () => {
    const styleRef1 = useRef();
    const styleRef2 = useRef();
    const styleRef3 = useRef();
    const styleRef4 = useRef();

  // 初始加载
    useEffect(() => {}, []);

    const handleClick = (link) => {
        // console.log(link);
    };
    const handleChange = (link) => {
        // console.log(link);
    };

    const anchorList = [
        {
            key: 'module-1',
            href: '#module-1',
            title: '产品介绍',
        },
        {
            key: 'module-2',
            href: '#module-2',
            title: '应用场景',
        },
        {
            key: 'module-3',
            href: '#module-3',
            title: '产品功能',
        },
        {
            key: 'module-4',
            href: '#module-4',
            title: '产品优势',
        },
    ];

    const sceneList = [
        {
            title: '“两客一危一重”安全监管',
            img: scene5,
            content: '面向“两客一危”安全监管，针对车辆驾驶过程中发生的如“三超一疲劳”的告警数据进行实时接入，依托风险隐患模型进行风险隐患的自动识别，提供监管端、企业和从业人员三端联动闭环的处理能力，从而降低“两客一危”车辆的运行风险。'
        },
        {
            title: '出租网约车安全监管',
            img: scene6,
            content: '面向出租网约车的安全监管，针对车辆驾驶过程中发生的“非法营运”、违规载客行为、危险驾驶行为等提供监管端、企业和从业人员三端联动闭环的处理能力，从而降低出租网约车驾驶过程中的运行风险。'
        },
        {
            title: '公交车运营监测',
            img: scene7,
            content: '面向公交车安全监管，提供公交车实时行驶监测及运行情况分析，提供监管端、企业和从业人员三端联动闭环的处理能力，从而提升公交车运营水平。'
        },
        {
            title: '共享电单车安全监管',
            img: scene8,
            content: '面向共享电单车的安全监管，针对车辆营运过程中发生的车辆乱停乱放、企业超区超量投放、“僵尸车”及车辆设备不安全等风险隐患，提供监管端、企业和从业人员三端联动闭环的处理能力，从而提升共享电单车文明出行水平。'
        },
        {
            title: '校车安全监管',
            img: scene9,
            content: '面向校车的安全监管，针对车辆营运过程中发生的空载使用校车标牌、停靠站点不合规、校车驾驶资格证即将到期等风险隐患，提供监管端、企业和从业人员三端联动闭环的处理能力，从而降低校车的安全出行风险。'
        },
    ]
    return (
        <div className={styles.container}>
            <div className={styles.banner}>
                <img src={banner}/>
                <div className={styles.desc}>营运车辆大数据安全监管平台</div>
            </div>

            <Anchor data={anchorList} onChange={handleChange} onClick={handleClick}/>

            <div id="module-1" className={styles.products}>
                <div className={styles.title}>产品介绍</div>
                <div className={styles.content}>面向“两客一危”、公交车、出租网约车、共享电单车等营运车辆的安全管理，提供“全范围覆盖、全过程记录、全数据监管”的风险隐患监测能力和全流程闭环的处理能力。</div>
            </div>

            <AppScene id={'module-2'} data={sceneList} />

            <div id="module-3" className={styles.function}>
                <div className={styles.title}>产品功能</div>
                <div className={styles.mid}>
                    <div className={styles.content}>
                        <div className={styles.header}>面向监管端</div>
                        <div className={styles.item}>
                            <img src={func1}/>
                            <div className={styles.right}>
                                <div className={styles.head}>安全监测</div>
                                <div className={styles.main}>提供覆盖车辆、驾驶人、设备的隐患监测功能、风险监测功能及针对企业的安全分监管功能</div>
                            </div>
                        </div>
                        <div className={styles.item}>
                            <img src={func2}/>
                            <div className={styles.right}>
                                <div className={styles.head}>运营监测</div>
                                <div className={styles.main}>提供营运车辆运营过程中的运营监测概览、企业运营监测及企业提醒记录功能</div>
                            </div>
                        </div>
                        <div className={styles.item}>
                            <img src={func3}/>
                            <div className={styles.right}>
                                <div className={styles.head}>数据分析</div>
                                <div className={styles.main}>提供风险数据分析、隐患数据分析等功能</div>
                            </div>
                        </div>
                        <div className={styles.item}>
                            <img src={func4}/>
                            <div className={styles.right}>
                                <div className={styles.head}>配置中心</div>
                                <div className={styles.main}>提供企业配置、风险配置、隐患配置、接入配置、行业配置、流程配置功能</div>
                            </div>
                        </div>
                        <div className={styles.item}>
                            <img src={func5}/>
                            <div className={styles.right}>
                                <div className={styles.head}>档案中心</div>
                                <div className={styles.main}>提供企业档案、车辆档案寄驾驶人档案功能</div>
                            </div>
                        </div>
                        <div className={styles.item}>
                            <img src={func6}/>
                            <div className={styles.right}>
                                <div className={styles.head}>审批中心</div>
                                <div className={styles.main}>提供隐患审批、业务审批等功能</div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.content}>
                        <div className={styles.header}>面向企业端</div>
                        <div className={styles.item}>
                            <img src={func7}/>
                            <div className={styles.right}>
                                <div className={styles.head}>风险隐患处置</div>
                                <div className={styles.main}>提供企业对风险及隐患的管理及处置功能</div>
                            </div>
                        </div>
                        <div className={styles.item}>
                            <img src={func8}/>
                            <div className={styles.right}>
                                <div className={styles.head}>业务办理</div>
                                <div className={styles.main}>提供企业备案、车辆备案等业务办理功能</div>
                            </div>
                        </div>
                        <div className={styles.item}>
                            <img src={func9}/>
                            <div className={styles.right}>
                                <div className={styles.head}>车辆管理</div>
                                <div className={styles.main}>提供企业车辆录入及管理功能</div>
                            </div>
                        </div>
                        <div className={styles.item}>
                            <img src={func3}/>
                            <div className={styles.right}>
                                <div className={styles.head}>数据分析</div>
                                <div className={styles.main}>提供企业风险隐患数据、车辆数据的统计分析功能</div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.content}>
                        <div className={styles.header}>面向个人端</div>
                        <div className={styles.item}>
                            <img src={func7}/>
                            <div className={styles.right}>
                                <div className={styles.head}>风险处置</div>
                                <div className={styles.main}>提供从业人员对风险及隐患的处理功能</div>
                            </div>
                        </div>
                        <div className={styles.item}>
                            <img src={func8}/>
                            <div className={styles.right}>
                                <div className={styles.head}>业务办理</div>
                                <div className={styles.main}>提供从业人员业务办理功能</div>
                            </div>
                        </div>
                        <div className={styles.item}>
                            <img src={func3}/>
                            <div className={styles.right}>
                                <div className={styles.head}>数据分析</div>
                                <div className={styles.main}>提供从业人员个人信用分等统计分析功能</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.iframe}>
                    <img src={frame2}/>
                </div>
            </div>

            <div id="module-4" className={styles.superiority}>
                <div className={styles.title}>产品优势</div>
                <div className={styles.bottom}>
                    <div className={styles.cont} ref={styleRef1}>
                        <div className={styles.ani}>
                            <Animation5 element={styleRef1} />
                        </div>
                        <div className={styles.name}>形成政府风险管控、企业风险处置、驾驶人风险反馈的“三端闭环”管理</div>
                        <div className={styles.desc}>
                            <div className={styles.point}>政府管理部门：利用大数据手段，基于行业指导经验，建立各类风险指标及模型，向道路运输企业下发管控指导；</div>
                            <div className={styles.point}>企业：根据政府指导对运营风险及通告进行处置并反馈至政府管理部门，同时向相应的从业人员下发提醒并持续跟进从业人员后续的驾驶行为；</div>
                            <div className={styles.point}>从业人员：可查看风险记录，规范自身驾驶行为。</div>
                        </div>
                    </div>
                    <div className={styles.cont} ref={styleRef2}>
                        <div className={styles.ani}>
                            <Animation3 element={styleRef2} />
                        </div>
                        <div className={styles.name}>结合风险隐患模型，实现风险隐患监管灵活配置、分级处理</div>
                        <div className={styles.desc}>
                            <div className={styles.point}>对营运车辆、驾驶人行驶过程进行全程监管，建立风险点模型和隐患模型；</div>
                            <div className={styles.point}>模型可识别超速、驾驶员危险驾驶、不按证件行驶违规驾驶行为、设备上线异常等风险隐患行为，支持对风险隐患进行灵活配置、分级管理。</div>
                        </div>
                    </div>
                    <div className={styles.cont} ref={styleRef3}>
                        <div className={styles.ani}>
                            <Animation2 element={styleRef3} />
                        </div>
                        <div className={styles.name}>构建事前预防、事中预警、事后分析<br/>闭环监管</div>
                        <div className={styles.desc}>
                            <div className={styles.point}>事前：对风险进行事前预测，提前规避事故发生；</div>
                            <div className={styles.point}>事中：对车辆及驾驶人的各类业务风险的全面监管，及时对事故风险进行应对，对多部门协同监管提供支撑；</div>
                            <div className={styles.point}>事后：通过人、车、企档案进行事后分析，有效加强企业、车辆的安全管理，降低事故发生率。</div>
                        </div>
                    </div>
                    <div className={styles.cont} ref={styleRef4}>
                        <div className={styles.ani}>
                            <Animation1 element={styleRef4} />
                        </div>
                        <div className={styles.name}>结合全息档案、信用分评级评价体系，创新隐患风险管理模式</div>
                        <div className={styles.desc}>
                            <div className={styles.point}>个人信用：结合交通守法、安全行驶、基础信息、文明活动、信用履约五个维度建立个人信用评估模型；</div>
                            <div className={styles.point}>企业信用：结合经营规模、守法出行、安全管理、驾驶人信用水平、风险累积、风险监控六个维度创建企业信用评估模型。</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Vehicle;
