import React, {useEffect, useState} from "react";
import styles from "./index.module.scss";
import classnames from "classnames";

const Animation = (props) => {
    const {
        element = null
    } = props;
    const [hover, setHover] = useState(false);

    useEffect(() => {
        let sleep = null;
        element.current.onmouseenter = () => {
            setHover(true);
            sleep = setTimeout(() => {
                setHover(false);
            }, 1000);
        }
        element.current.onmouseleave = () => {
            setHover(false);
            if (sleep) clearTimeout(sleep)
        }
    }, [element]);

    return (
        <div className={styles.animation}>
            <span className={classnames({
                [styles.blockL]: true,
                [styles.list1]: hover,
            })}></span>
            <span className={classnames({
                [styles.blockR]: true,
                [styles.list2]: hover,
            })}></span>
            <div className={styles.middle}></div>
        </div>
    )
};

export default Animation;
