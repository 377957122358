import React, {useEffect, useState} from "react";
import styles from "./index.module.scss";
import classnames from "classnames";

const Animation = (props) => {
    const {
        element = null
    } = props;
    const [hover, setHover] = useState(false);

    useEffect(() => {
        let sleep = null;
        element.current.onmouseenter = () => {
            setHover(true);
            sleep = setTimeout(() => {
                setHover(false);
            }, 1000);
        }
        element.current.onmouseleave = () => {
            setHover(false);
            if (sleep) clearTimeout(sleep)
        }
    }, [element]);
    return (
        <div className={styles.animation}>
            <div className={styles.top}></div>
            <div className={classnames({
                [styles.mid]: true,
                [styles.animateM]: hover,
            })}></div>
            <div className={styles.bottom}></div>
            <div className={classnames({
                [styles.blockL]: true,
                [styles.animateL]: hover,
            })}></div>
            <div className={classnames({
                [styles.blockR]: true,
                [styles.animateR]: hover,
            })}></div>
        </div>
    )
};

export default Animation;
