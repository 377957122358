import React from 'react';
import { Provider } from 'react-redux';
import store from '@/store';
import { ConfigProvider } from 'antd';
import Router from './routes';
import zhCN from 'antd/lib/locale/zh_CN';
// import './styles/alibabaPuHui.scss';
import './styles/YouSheBiaoTiHei.scss';
import '@/styles/common.scss';

const App = () => {
  return (
    <div className="App">
      <ConfigProvider locale={zhCN}>
        <Provider store={store}>
          <Router />
        </Provider>
      </ConfigProvider>
    </div>
  );
};

export default App;
