/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import { connect } from "react-redux";
import classnames from "classnames";
import styles from "./index.module.scss";
import logo from "@/assets/header/logo.png";
import logo1 from "@/assets/header/logo1.png";
import tag from "@/assets/header/tag.png";
import menuLogo from "@/assets/header/menuLogo.png";

const Header = (props) => {
  const {
    location,
    history,
    background,
    bannerTextColor,
    bannerBorderColor,
    logoDark,
  } = props;

  const [isScroll, setIsScroll] = useState(false);
  const [position, setPosition] = useState("");
  const [isHome, setIsHome] = useState();
  const [isHover, setIsHover] = useState(false);
  const [checkedItem, setCheckedItem] = useState();
  const [checkedContentItem, setCheckedContentItem] = useState();
  const [hoverText, setHoverText] = useState("");
  const menuArr = [
    { name: "首页", isChildren: false, address: "/home" },
    {
      name: "核心产品",
      isChildren: true,
      address: "/coreProduct",
      children: [
        {
          name: "交通运输",
          subChildren: [
            { name: "智慧交通平台（TOCC）", address: "/tocc" },
            { name: "营运车辆大数据安全监管平台", address: "/vehicle" },
          ],
        },
        {
          name: "交通管理",
          subChildren: [
            { name: "交通管理大数据研判分析平台", address: "/judge" },
          ],
        },
        {
          name: "数据服务",
          subChildren: [{ name: "大数据基础平台", address: "/data" }],
        },
      ],
    },
    {
      name: "解决方案",
      isChildren: true,
      address: "/solution",
      children: [
        {
          title: "政府业务",
          logo: menuLogo,
          data: [
            {
              name: "道路运输安全监管",
              subChildren: [
                { name: "两客一危安全监管大数据解决方案", address: "/lkyw" },
                {
                  name: "共享电单车安全监管大数据解决方案",
                  address: "/scooter",
                },
              ],
            },
            {
              name: "大数据交通运输",
              subChildren: [
                { name: "智慧交通平台大数据解决方案", address: "/city" },
              ],
            },
            {
              name: "大数据交通管理",
              subChildren: [
                { name: "交管全业务监管大数据解决方案", address: "/traffic" },
              ],
            },
          ],
        },
        // {
        //   title: "企业业务",
        //   logo: menuLogo,
        //   data: [],
        // },
      ],
    },
    { name: "数据服务", isChildren: false, address: "/dataService" },
    { name: "高校合作", isChildren: false, address: "/cooperation" },
    { name: "关于我们", isChildren: false, address: "/aboutUs" },
  ];
  const handleGo = (href) => {
    history.push(href);
    setHoverText("");
  };
  useEffect(() => {
    let nameArr = location.pathname.split("/");
    setCheckedItem("/" + nameArr[1]);
    setCheckedContentItem("/" + nameArr[2]);
    if (["/home"].includes(location.pathname)) {
      window.addEventListener("scroll", handleScroll);
      setPosition("sticky");
      setIsHome(true);
    } else {
      setPosition("relative");
      setIsHome(false);
    }
    document.documentElement.scrollTop = 0;
    // 组件卸载前移除监听事件
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [location.pathname]);
  const handleScroll = () => {
    if (
      !!document.documentElement.scrollTop &&
      document.documentElement.scrollTop > 0
    ) {
      setIsScroll(true);
    } else {
      setIsScroll(false);
    }
  };

  return (
    <div
      className={styles.header}
      style={{
        background:
          isScroll || isHover ? "#fff" : isHome ? "transparent" : "#fff",
        position: position,
        borderColor: bannerBorderColor,
      }}
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
    >
      <div className={styles.logoBox}>
        <img
          src={
            isHome && !isScroll && !isHover ? (logoDark ? logo : logo1) : logo
          }
        ></img>
      </div>
      <div className={styles.menu}>
        {menuArr.map((v, index) => (
          <div
            className={classnames({
              [styles.menuItem]: true,
              [styles.checkedItem]: v.address == checkedItem,
            })}
            key={index}
            onMouseEnter={() => {
              setHoverText(v.address);
            }}
            onMouseLeave={() => {
              setHoverText("");
            }}
          >
            {v.isChildren ? (
              <span
                className={styles.menuName}
                style={{
                  color:
                    isHome && !isScroll && !isHover
                      ? bannerTextColor
                      : "#333333",
                }}
              >
                {v.name}
              </span>
            ) : (
              <span
                className={styles.menuName}
                style={{
                  color:
                    isHome && !isScroll && !isHover
                      ? bannerTextColor
                      : "#333333",
                }}
                onClick={() => handleGo(v.address)}
              >
                {v.name}
              </span>
            )}
            {/* 通过二级菜单数量判断对齐方式 */}
            {v.address == "/coreProduct" && (
              <div
                style={{
                  height: hoverText == "/coreProduct" ? "332px" : "0",
                }}
                className={classnames(styles.subMenuBox,styles.coreProductBox)}
              >
                <div className={styles.subMenu}>
                  {v?.children?.map((val, index) => (
                    <div className={styles.subMenuItem} key={index}>
                      <div className={styles.title}>
                        <img src={tag} />
                        {val.name}
                      </div>
                      <div className={styles.content}>
                        {val.subChildren.map((value) => (
                          <div
                            key={value.address}
                            className={classnames({
                              [styles.contentItem]: true,
                              [styles.checkedContentItem]:
                                value.address == checkedContentItem,
                            })}
                            onClick={() => handleGo(v.address + value.address)}
                          >
                            {value.name}
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {v.address == "/solution" && (
              <div
                style={{ height: hoverText == "/solution" ? "332px" : "0" }}
                className={classnames(styles.subMenuBox,styles.solutionBox)}
              >
                <div className={styles.subMenu}>
                  <Tabs
                    tabPosition={"left"}
                    items={v?.children?.map((val, index) => {
                      return {
                        label: (
                          <span className={styles.tabItemTitle}>
                            <img src={val.logo} />
                            {val.title}
                          </span>
                        ),
                        key: index,
                        children: (
                          <div className={styles.tabItem}>
                            {val.data?.map((item, i) => (
                              <div key={i} className={styles.tabItemCon}>
                                <div className={styles.title}>{item.name}</div>
                                <div className={styles.content}>
                                  {item.subChildren.map((value) => (
                                    <div
                                      key={value.address}
                                      className={classnames({
                                        [styles.contentItem]: true,
                                        [styles.checkedContentItem]:
                                          value.address == checkedContentItem,
                                      })}
                                      onClick={() =>
                                        handleGo(v.address + value.address)
                                      }
                                    >
                                      {value.name}
                                    </div>
                                  ))}
                                </div>
                              </div>
                            ))}
                          </div>
                        ),
                      };
                    })}
                  />
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
      <button
        onClick={() =>
          window.open("http://ysssonew.zcreate.com.cn:8888/#/login")
        }
      >
        产品演示
      </button>
    </div>
  );
};
const mapStateToProps = (store) => {
  return {
    bannerTextColor: store.common.bannerTextColor,
    bannerBorderColor: store.common.bannerBorderColor,
    logoDark: store.common.logoDark,
  };
};
const mapDispatchToProps = (store) => {
  return {
    setState: store.common.setState,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);
