const home = {
  state: {
  },
  reducers: {
    setState(state, payload) {
      return { ...state, ...payload };
    },
  },
  effects: {
    async getMenu() {
      const menu = [1, 3, 5, 5]
      return menu;
    },
  },
};

export default {
  home
};
