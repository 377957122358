import React, {useEffect, useRef, useState} from "react";
import {Anchor, Animation} from '@/components'
import AppScene from '../components/AppScene'
import styles from "./index.module.scss";
import banner from 'assets/core/banner3.jpg';
import scene10 from '@/assets/core/scene10.png';
import scene11 from '@/assets/core/scene11.png';
import scene12 from '@/assets/core/scene12.png';
import frame3 from 'assets/core/framework3.png';
import func1 from '@/assets/core/judge_func1.png';
import func2 from '@/assets/core/judge_func2.png';
import func3 from '@/assets/core/judge_func3.png';
import func4 from '@/assets/core/judge_func4.png';
import func5 from '@/assets/core/judge_func5.png';
import func6 from '@/assets/core/judge_func6.png';
import func7 from '@/assets/core/judge_func7.png';

const { Animation2, Animation3, Animation4 } = Animation;

// 研判平台
const Judge = () => {
    const styleRef1 = useRef();
    const styleRef2 = useRef();
    const styleRef3 = useRef();

    const handleClick = (link) => {
        // console.log(link);
    };
    const handleChange = (link) => {
        // console.log(link);
    };

    const anchorList = [
        {
            key: 'module-1',
            href: '#module-1',
            title: '产品介绍',
        },
        {
            key: 'module-2',
            href: '#module-2',
            title: '应用场景',
        },
        {
            key: 'module-3',
            href: '#module-3',
            title: '产品功能',
        },
        {
            key: 'module-4',
            href: '#module-4',
            title: '产品优势',
        },
    ];

    const sceneList = [
        {
            title: '针对执法人员',
            img: scene10,
            content: '针对执法人员进行醉驾违规改成酒驾、违规撤销酒驾、同一地点同一时间对同一辆车开具多起违法、事故人员未及时吊销驾驶证等业务违规操作风险监管。'
        },
        {
            title: '针对业务办理人员',
            img: scene11,
            content: '针对业务办理人员进行驾考作弊、违规选取吉祥号、违规发放临时号牌、夜间处理非现场违法等业务违规操作风险监管。'
        },
        {
            title: '针对不法分子',
            img: scene12,
            content: '针对不法分子进行买卖分、事故碰瓷、报废车上路、利用已故驾驶人证照处理违法等违法行为进行监管。'
        },
    ]
    return (
        <div className={styles.container}>
            <div className={styles.banner}>
                <img src={banner}/>
                <div className={styles.desc}>交通管理大数据研判分析平台</div>
            </div>

            <Anchor data={anchorList} onChange={handleChange} onClick={handleClick}/>

            <div id="module-1" className={styles.products}>
                <div className={styles.title}>产品介绍</div>
                <div className={styles.content}>面向交通管理实现“全业务监管”，通过建立机动车、驾驶员、违法、事故、驾考等多个场景风险监管模型，提供高效的数据分析研判工具，提升交通管理风险研判效率。</div>
            </div>

            <AppScene id={'module-2'} data={sceneList} />

            <div id="module-3" className={styles.function}>
                <div className={styles.title}>产品功能</div>
                <div className={styles.mid}>
                    <div className={styles.content}>
                        <img src={func1}/>
                        <div className={styles.right}>
                            <div className={styles.head}>监管中心</div>
                            <div className={styles.main}>提供机动车业务监管、驾考业务监管、驾驶证业务监管、违法业务监管及事故业务监管的功能。</div>
                        </div>
                    </div>
                    <div className={styles.content}>
                        <img src={func2}/>
                        <div className={styles.right}>
                            <div className={styles.head}>分析中心</div>
                            <div className={styles.main}>提供机动车业务分析、驾考业务分享、驾驶证业务分析、违法业务分析及事故业务分析的功能。</div>
                        </div>
                    </div>
                    <div className={styles.content}>
                        <img src={func3}/>
                        <div className={styles.right}>
                            <div className={styles.head}>全息档案</div>
                            <div className={styles.main}>提供一人一档、一车一档、一企一档、一路一档和一警一档的综合档案管理功能。</div>
                        </div>
                    </div>
                    <div className={styles.content}>
                        <img src={func4}/>
                        <div className={styles.right}>
                            <div className={styles.head}>关系网络</div>
                            <div className={styles.main}>提供人、车、企关系网络图集关系网络分析功能。</div>
                        </div>
                    </div>
                    <div className={styles.content}>
                        <img src={func5}/>
                        <div className={styles.right}>
                            <div className={styles.head}>流程管理</div>
                            <div className={styles.main}>提供业务配置、处理员组配置、流程配置功能。&nbsp;</div>
                        </div>
                    </div>
                    <div className={styles.content}>
                        <img src={func6}/>
                        <div className={styles.right}>
                            <div className={styles.head}>模型站</div>
                            <div className={styles.main}>提供模型库及标签库的管理配置功能。</div>
                        </div>
                    </div>
                    <div className={styles.content}>
                        <img src={func7}/>
                        <div className={styles.right}>
                            <div className={styles.head}>态势中心</div>
                            <div className={styles.main}>提供违法态势分析、事故态势分析功能。</div>
                        </div>
                    </div>
                </div>
                <div className={styles.iframe}>
                    <img src={frame3}/>
                </div>
            </div>

            <div id="module-4" className={styles.superiority}>
                <div className={styles.title}>产品优势</div>
                <div className={styles.bottom}>
                    <div className={styles.cont} ref={styleRef1}>
                        <div className={styles.ani}>
                            <Animation2 element={styleRef1} />
                        </div>
                        <div className={styles.name}>交管监管业务全覆盖</div>
                        <div className={styles.desc}>对公安交管机动车、驾驶证、驾考、违法、事故等5大业务进行深入分析研判，通过融合公安网、视频网、互联网，形成人、车、路、企、设备等各信息维度的综合分析研判。</div>
                    </div>
                    <div className={styles.cont} ref={styleRef2}>
                        <div className={styles.ani}>
                            <Animation4 element={styleRef2} />
                        </div>
                        <div className={styles.name}>提供研判分析可支撑工具</div>
                        <div className={styles.desc}>结合模型站、全息档案和关系网络，对机动车、驾驶证、驾考、事故、违法等业务研判分析提供数据及工具支撑，将业务中存在的风险进行实时化、智能化、可视化、流程闭环化的监督管理。实现风险的“早发现、早预警、早处理”。</div>
                    </div>
                    <div className={styles.cont} ref={styleRef3}>
                        <div className={styles.ani}>
                            <Animation3 element={styleRef3} />
                        </div>
                        <div className={styles.name}>重点目标重点管控</div>
                        <div className={styles.desc}>结合标签体系、全息档案和关系网络，识别重点对象，对重点驾驶人、重点车辆、重点监管企业进行重点管控。</div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Judge;
