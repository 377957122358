import React, { useEffect, useState, useRef } from "react";
import {Anchor} from '@/components'
import { Image, Popover } from 'antd';
import styles from "./index.module.scss";

import banner from '@/assets/about/banner.png';
import honourTitle from '@/assets/about/honour-title.png';
import naturalTitle from '@/assets/about/natural-title.png';
import honour1 from '@/assets/about/honour1.png';
import honour2 from '@/assets/about/honour2.png';
import honour3 from '@/assets/about/honour3.png';
import honour4 from '@/assets/about/honour4.png';
import natural1 from '@/assets/about/natural1.png';
import natural2 from '@/assets/about/natural2.png';
import natural3 from '@/assets/about/natural3.png';
import natural4 from '@/assets/about/natural4.png';
import activity1 from '@/assets/about/activity1.png';
import activity2 from '@/assets/about/activity2.png';
import activity3 from '@/assets/about/activity3.png';
import activity4 from '@/assets/about/activity4.png';
import activity5 from '@/assets/about/activity5.png';
import activity6 from '@/assets/about/activity6.png';
import activity7 from '@/assets/about/activity7.png';
import activity8 from '@/assets/about/activity8.png';
import activity9 from '@/assets/about/activity9.png';
import activity10 from '@/assets/about/activity10.png';
import phone from '@/assets/about/phone.png';
import email from '@/assets/about/email.png';
import address from '@/assets/about/address.png';
import phoneBg from '@/assets/about/phone_bg.png';
import emailBg from '@/assets/about/email_bg.png';
import addressBg from '@/assets/about/address_bg.png';

import Course from './components/Course'

// 关于我们
const AboutUs = (props) => {

  const module1 = useRef();

  const module2 = useRef();

  useEffect(() => {
    window.addEventListener('scroll', HandleScroll)
    return () => {
      window.removeEventListener('scroll', HandleScroll)
    }
  }, []);


  const HandleScroll = () => {

  }

  const anchorList = [
    {
      key: 'module-1',
      href: '#module-1',
      title: '智诚简介',
    },
    {
      key: 'module-2',
      href: '#module-2',
      title: '企业历程',
    },
    {
      key: 'module-3',
      href: '#module-3',
      title: '荣誉资质',
    },
    {
      key: 'module-4',
      href: '#module-4',
      title: '文化活动',
    },
    {
      key: 'module-5',
      href: '#module-5',
      title: '联系我们',
    },
  ]

  const courseList = [
    {
      key: 0,
      date: '2013',
      title: '2013 公司成立',
      keynote: [
          '1、公司成立，作为首批入驻贵阳高新区的企业',
          '2、以城市级“天网工程”咨询设计为出发点，业务遍布贵阳、仁怀、六盘水等地',
      ]
    },
    {
      key: 1,
      date: '2014',
      title: '2014 立足本地',
      keynote: [
        '1、羽翼渐丰，组织完善，业务稳定，增长快速',
        '2、开启了为期5年，占地720万平米，常驻人口20万的超级城市综合体中天·未来方舟的规划、设计、建设总集成项目，在城市综合体、智慧社区领域快速成长',
      ]
    },
    {
      key: 2,
      date: '2015',
      title: '2015 渐入佳境',
      keynote: [
        '1、公司高速发展，年增长率超过300%',
        '2、创新定义“数据集成服务”，完成“运用大数据编织制约权力的笼子”理念的落地，帮助贵阳市18个政府部门落地“数据铁笼”项目的建设',
        '3、注重数据价值挖掘，开创性完成国内首个“交通大数据孵化器”的落地建设',
        '4、总裁徐桂林先生作为贵漂代表受到习主席接见',
      ]
    },
    {
      key: 3,
      date: '2016',
      title: '2016 自主研发',
      keynote: [
        '1、自主研发“数据质量管控系统”，填补行业空白',
        '2、荣获“高新技术企业”认定，持续进行技术成果的转化',
        '3、结合自身多年深耕（大数据）行业的经验，参与省、市多项大数据体系标准的制定',
      ]
    },
    {
      key: 4,
      date: '2017',
      title: '2017 全面起航',
      keynote: [
        '1、通过CMMI3认证，研发部完成从成立到成熟的快速成长',
        '2、技术立企，自主研发智能一体化机柜、智能环境监测仪等物联网设备',
        '3、立足数据集成服务，完成贵阳市公安块数据指挥中心顶层规划设计',
        '4、深入“智慧教育”领域，承接中天中学智慧校园的设计及实施',
      ]
    },
    {
      key: 5,
      date: '2018',
      title: '2018 深度合作',
      keynote: [
        '1、全面引领智慧城市落地，陆续完成纳雍、赫章、镇宁、镇远、务川等多地智慧城市规划设计',
        '2、深耕“智能交通”，完成贵阳市1.5环、火车北站、同城大道、花冠路、人民大道等智能交通项目规划设计',
        '3、助力技术标准规范落地，成为国家技术标准创新基地（贵州大数据）建设发展委员会副主任委员单位',
      ]
    },
    {
      key: 6,
      date: '2019',
      title: '2019 开拓创新',
      keynote: [
        '1、牵头省科技厅重大专项—“基于城市道路车路协同的无人驾驶车辆关键技术研究与示范项目',
        '2、牵头成立国家技术标准创新基地（贵州大数据）交通大数据委员会',
        '3、成功立项三项地方行业标准—《智能交通系统建设规范》、《空铁联运数据接入技术规范》、《网络预约出租车车载终端及平台技术规范》',
        '4、发力智慧交通行业，重点突破智慧交通大数据平台、智慧停车数据中台、智慧道路三大领域',
        '5、贵州省大数据企业50强',
      ]
    }, {
      key: 7,
      date: '2020',
      title: '2020 逆流而上',
      keynote: [
        '1、疫情期间，率先发布交通出行抗疫小程序，确保居民出行有迹可循',
        '2、构建交通管理和交通运输产品解决方案，形成公司自主产品体系',
        '3、成立成都、昆明、天津办事处，扎根贵州，迈向全国',
        '4、成功获得CMMI-5认证，标志公司软件过程成熟度达到世界一流水平',
      ]
    },
    {
      key: 8,
      date: '2021',
      title: '2021 千锤百炼',
      keynote: [
        '1、建立健全G、B、C三端产品体系，推动产品在交通运输、交通管理行业成熟落地',
        '2、以“数据质量运维工具+数据服务团队”的新型模式，构建城市级、精细化的运维服务体系',
        '3、荣获贵阳市“专精特新”中小企业称号、数博会“领先科技成果”优秀奖、贵阳市高新技术产业协会副会长单位',
      ]
    },
    {
      key: 9,
      date: '2022',
      title: '2022 砥砺前行',
      keynote: [
        '1、产品成功转化，全交管体系在黔南州落地',
        '2、注重数据安全与数据成熟度运用，成功获得DSMM2级的评估',
        '3、坚持自主创新，成功获得省级专精特新中小企业；坚持知识产权保护，成功获得省级知识产权优势企业',
      ]
    },
    {
      key: 10,
      date: '2023',
      title: '2023 厚积薄发',
      keynote: [
        '1、持续优化、挖掘产品应用场景，提升产品竞争力',
        '2、立足贵州市场，实现省外市场重大突破，产品推广落地',
        '3、激活组织，团结一心，奋力拼搏，共建共治共享',
      ]
    },
  ];

  const activity = [
    [
      {
        title: '突破创新·奋勇前进|贵州智诚科技迎新年会暨2022年度表彰大会',
        desc: '天地春晖近，日月开新元。蓦然回首，2022年已然过去，在新春佳节来临之际，为了总结一年的工作成果，同时更为感谢公司全体员工一年来的辛勤工作，增强企业凝聚力，表彰优秀...',
        img: activity1,
        link: 'https://mp.weixin.qq.com/s/Mj7jCwVhg0udJdFozkWCDA'
      },{
        title: '智诚科技开展“2022年夏季拓展活动”！',
        desc: '2022年07月31日，贵阳市观山湖区-瑞斯特体育馆，夏日的炎阳丨清新的空气丨年轻的脸庞，贵州智诚科技的员工们汇聚于此！蓄势待发，青春运动正当时！',
        img: activity2,
        link: 'https://mp.weixin.qq.com/s/E_nN71Hu4n4z2S9a2BYoyg'
      }
    ],
    [
      {
        title: '智诚科技开展“2022年中总结暨产品特训”！',
        desc: '为提升员工服务技能与综合素质，打造优秀的企业员工团队，建立学习型企业，增强公司核心竞争力，适应公司对各类人才的需求，7月29日到30日，智诚科技组织员工开展了...',
        img: activity3,
        link: 'https://mp.weixin.qq.com/s/Ekng6X5MMjVYl_jl2BA8gw'
      },{
        title: '3.8女神节 | 自由的人生，独立而温暖！',
        desc: '“少女心和女王范，可以同属一个女孩儿”',
        img: activity4,
        link: 'https://mp.weixin.qq.com/s/Yj4FTuo3dXpSsGrnYuKoUQ'
      }
    ],
  ];
  const activityMore = [
    [
      {
        title: '同心同行 · 共享未来 | 贵州智诚科技迎新年会暨2021年度表彰大会',
        desc: '喜悦伴着汗水，成功伴着艰辛，遗憾激励奋斗，弹指间，2021年已向我们挥手告别。“不平凡”是2021的概括，黑暗之中的咬牙坚持，只为寻找能够引领我们走向破晓的那缕希...',
        img: activity5,
        link: 'https://mp.weixin.qq.com/s/LV4t0e658jKhSKzznCSa9A'
      },{
      title: '交流前沿话题 碰撞思想火花 | 团建联动·“智”说交通青年交流主题论坛成功举办',
      desc: '2021年11月24日下午，由交建集团团委、贵州智诚科技有限公司团支部主办，贵州路桥集团团委、贵州交建投资有限公司团委承办的团建联动·“智”说交通青年交流主题论坛在...',
      img: activity6,
      link: 'https://mp.weixin.qq.com/s/Hte8PwixsjYaQv7Jy0oYAQ'
    }
    ],
    [
      {
        title: '贵州交建集团携手智诚科技共同开展“团青牵手行动，共谋发展新篇”团建联动活动',
        desc: '2021年4月25日，贵州交通建设集团有限公司与贵州智诚科技有限公司联合开展了以“团青牵手行动，共谋发展新篇”为主题的团建联动活动。贵州交建集团团委书记朱明聪...',
        img: activity7,
        link: 'https://mp.weixin.qq.com/s/L5IXaAKXoJEgwyNEmmughA'
      },{
      title: '以篮球之名，建友谊之桥',
      desc: '2020年7月25日，为丰富员工业余文化生活，展示员工精神风貌，增强团队协作意识和公司凝聚力，也为建立兄弟单位友谊，促进与兄弟单位的交流与合作，贵州智诚科技与贵州...',
      img: activity8,
      link: 'https://mp.weixin.qq.com/s/p3m63tec2rbWATUKMuF_Og'
    }
    ],
    [
      {
        title: '六一福利|为智诚科技的宝贝们送上一份节日的祝福！',
        desc: '2020年6月1日，大家最期待的六一又来啦！虽然现在我们已然长成了大人的模样，在智诚科技的大家庭中我们共同奋斗在梦想的道路上',
        img: activity9,
        link: 'https://mp.weixin.qq.com/s/YHBprG8-SHI48iJGbk8P5w'
      },{
      title: '天寒暖人心，智诚科技致敬贵阳市交通一线民警',
      desc: '2020年1月20日，贵阳市文明交通诚信企业联盟携手贵阳交通广播1027，在贵阳市交警六大队负责的执勤点——贵阳东收费站举行新春温暖公益行活动，我公司作为贵阳市文明交...',
      img: activity10,
      link: 'https://mp.weixin.qq.com/s/P06qzx8pHdFdznm5UtAPWA'
    }
    ],
  ];

  const [activityList, setActivityList] = useState(activity);
  const [showBtn, setShowBtn] = useState(true);

  const handleNavTo = (link) => {
    window.open(link);
  };

  const handleLinkTo = () => {
    const url = 'https://map.baidu.com/search/%E8%B4%B5%E5%B7%9E%E6%99%BA%E8%AF%9A%E7%A7%91%E6%8A%80/@11872898.805,3057648.31,19z?querytype=s&da_src=shareurl&wd=%E8%B4%B5%E5%B7%9E%E6%99%BA%E8%AF%9A%E7%A7%91%E6%8A%80&c=146&src=0&wd2=%E8%B4%B5%E5%B7%9E&pn=0&sug=1&l=19&b=(11872443.805,3057445.81;11873403.805,3057910.31)&from=webmap&biz_forward=%7B%22scaler%22:1,%22styles%22:%22pl%22%7D&device_ratio=1'
    window.open(url);
  };

  const loadMore = () => {
    setActivityList((props) => {
      return props.concat(activityMore)
    })
    setShowBtn(false)
  };

  const retract = () => {
    setActivityList(activity)
    setShowBtn(true)
  };

  return (
      <div className={styles.container}>
        <div className={styles.banner}> <img src={banner}/> </div>
        <Anchor data={anchorList} />

        <div id="module-1" ref={module1} className={styles.introduce}>
          <div className={styles.title}>智诚简介<div className={styles.rect}></div></div>
          <div className={styles.content}>
            <p>贵州智诚科技有限公司成立于2013年，是贵州大数据产业谋划的首批企业。公司专注于交通+大数据的智慧交通领域，致力于成为专业的交通大数据服务提供商。公司总部设在贵阳，位处首个国家级大数据综合试验区。公司建立了贵阳—成都双研发中心，平均每年研发投入占比12%以上，目前在云南、四川、湖南和湖北等省份均设有分支机构。</p>
            <br/>
            <p>公司专注交通行业，通过大数据技术服务和赋能，打造面向政府、企业和民众的三端数据融合产品体系，构建政府开放数据、企业延伸创新应用、民众参与的创新机制，深化交通数据应用场景。
            面向交通运输管理部门，推出《营运车辆大数据安全监管平台》，通过对“两客一危”、渣土车、出租（网约车）、公交车、共享电单车等营运车辆运行过程中产生的数据进行融合分析，建立企业及从业人的风险评估模型、隐患模型、风险模型等70+模型，
            及时识别和预警风险，真正把事后分析变成事前预防，事中过程监督，进一步提升道路运输安全监管水平。面向公安交通管理部门，推出《交通管理大数据研判分析平台》，接入汇聚业务流程数据，对“人、车、证、路、警”等建立超500多项指标体系，
              建立机动车、驾驶员、违法、事故、驾考等169个业务场景风险监管模型，为交通管理者提供高效的数据分析研判工具，有效指导业务开展。</p>
            <br/>
            <p>经过多年发展，公司先后荣获了国家高新技术企业、贵州省“专精特新”中小企业、贵州大数据Top50企业、贵州省“百企引领”示范企业和贵州省企业技术中心等荣誉。拥有专利19件，其中发明1件、实用新型12件，外观专利6件，获得软件著作权72件，获得了CMMI5级、ITSS3级等资质证书。</p>
            <br/>
            <p>在“东数西算”国家战略工程，全国大数据蓬勃发展的大势下，智诚科技基于领先的产品优势和开拓精神，正逐步从贵州走向全国。我们将深耕交通行业，以“激活数据价值，引领交通创新”为使命，积极探索交通数据的商业模式，深入挖掘数据价值，不断为交通行业带来新的技术突破，推动交通大数据的产品和服务在全国范围内持续落地，致力于成为专业的交通大数据服务提供商。</p>
          </div>
        </div>

        <div id="module-2" ref={module2} className={styles.course}>
          <div className={styles.title}>企业历程</div>
          <Course data={courseList} showKey={10}/>
        </div>

        <div id="module-3" className={styles.honour}>
          <div className={styles.title}>资质证书</div>
          <div className={styles.honourTitle}><img src={honourTitle}/></div>
          <div className={styles.honourImg}>
            <div className={styles.item}>
              <div className={styles.imgBorder}><img src={honour1}/></div>
              <div className={styles.name}>国家高新技术企业</div>
            </div>
            <div className={styles.item}>
              <div className={styles.imgBorder}><img src={honour2}/></div>
              <div className={styles.name}>省级企业技术中心</div>
            </div>
            <div className={styles.item}>
              <div className={styles.imgBorder}><img src={honour3}/></div>
              <div className={styles.name}>省级专精特新中小企业</div>
            </div>
            <div className={styles.item}>
              <div className={styles.imgBorder}><img src={honour4}/></div>
              <div className={styles.name}>省级知识产权优势企业</div>
            </div>
          </div>
          <div className={styles.honourList}>
            <div className={styles.item}>
              <div className={styles.name}>国家高新技术企业</div>
              <div className={styles.name}>省级企业技术中心</div>
            </div>
            <div className={styles.item}>
              <div className={styles.name}>省级专精特新中小企业</div>
              <div className={styles.name}>省级知识产权优势企业</div>
            </div>
            <div className={styles.item}>
              <div className={styles.name}>贵州省大数据50强</div>
              <div className={styles.name}>贵州省大数据“百企引领”示范企业</div>
            </div>
            <div className={styles.item}>
              <div className={styles.name}>大数据与实体经济融合应用试点单位</div>
              <div className={styles.name}>国家科技型中小企业</div>
            </div>
            <div className={styles.item}>
              <div className={styles.name}>贵阳市创新型中小企业</div>
              <div></div>
            </div>
          </div>
          <div className={styles.honourTitle}><img src={naturalTitle}/></div>
          <div className={styles.honourImg}>
            <div className={styles.item}>
              <div className={styles.imgBorder2}>
                {/*<Image src={natural1}/>*/}
                <img src={natural1}/>
              </div>
              <div className={styles.name}>CMMI5</div>
            </div>
            <div className={styles.item}>
              <div className={styles.imgBorder2}><img src={natural2}/></div>
              <div className={styles.name}>DSMM2</div>
            </div>
            <div className={styles.item}>
              <div className={styles.imgBorder2}><img src={natural3}/></div>
              <div className={styles.name}>ITSS3</div>
            </div>
            <div className={styles.item}>
              <div className={styles.imgBorder2}><img src={natural4}/></div>
              <div className={styles.name}>知识产权管理体系认证</div>
            </div>
          </div>
          <div className={styles.honourList}>
            <div className={styles.item}>
              <div className={styles.name}>CMMI5证书(软件能力成熟度模型5级)</div>
              <div className={styles.name}>DSMM2级（数据安全能力成熟度模型）</div>
            </div>
            <div className={styles.item}>
              <div className={styles.name}>ITSS3级证书（信息技术服务标准）</div>
              <div className={styles.name}>知识产权管理体系认证</div>
            </div>
            <div className={styles.item}>
              <div className={styles.name}>ISO27001(信息安全管理体系认证）</div>
              <div className={styles.name}>ISO20000(信息技术服务管理体系认证)</div>
            </div>
            <div className={styles.item}>
              <div className={styles.name}>ISO14001(环境管理体系认证)</div>
              <div className={styles.name}>ISO9001(质量管理体系认证）</div>
            </div>
            <div className={styles.item}>
              <div className={styles.name}>ISO45001(职业健康管理体系认证）</div>
              <div className={styles.name}>诚信经营示范单位AAA</div>
            </div>
            <div className={styles.item}>
              <div className={styles.name}>企业信用等级AAA</div>
              <div className={styles.name}>企业资信等级AAA</div>
            </div>
            <div className={styles.item}>
              <div className={styles.name}>质量服务诚信单位AAA</div>
              <div className={styles.name}>重服务守信用单位AAA</div>
            </div>
            <div className={styles.item}>
              <div className={styles.name}>重质量守信用单位AAA</div>
              <div className={styles.name}>重合同守信用企业AAA</div>
            </div>
            <div className={styles.item}>
              <div className={styles.name}>增值电信业务经营许可证</div>
              <div></div>
            </div>
          </div>
        </div>

        <div id="module-4" className={styles.activity}>
          <div className={styles.title}>文化活动</div>
          <div className={styles.content}>
            {
              activityList.map((item, idx) => (
                  <div key={idx} className={styles.rows}>
                    <div className={styles.item}>
                      <img src={item[0].img}/>
                      <div className={styles.desc}>
                        <div className={styles.header}>{item[0].title}</div>
                        <div className={styles.words}>{item[0].desc}</div>
                        <div className={styles.link} onClick={() => handleNavTo(item[0].link)}>查看详情 <div className={styles.arrow}></div></div>
                      </div>
                    </div>
                    <div className={styles.item}>
                      <img src={item[1].img}/>
                      <div className={styles.desc}>
                        <div className={styles.header}>{item[1].title}</div>
                        <div className={styles.words}>{item[1].desc}</div>
                        <div className={styles.link} onClick={() => handleNavTo(item[1].link)}>查看详情 <div className={styles.arrow}></div></div>
                      </div>
                    </div>
                  </div>
              ))
            }
          </div>
          {
            showBtn ? <div className={styles.more} onClick={loadMore}>查看更多</div> : <div className={styles.more} onClick={retract}>收 起</div>
          }

        </div>

        <div id="module-5" className={styles.contact}>
          <div className={styles.title}>联系我们</div>
          <div className={styles.rows}>
            <div className={styles.item} style={{ backgroundImage: `url(${phoneBg})` }}>
              <img src={phone}/>
              <div className={styles.desc}>
                <div className={styles.top}>联系电话</div>
                <div className={styles.bottom}>0851-84828040</div>
              </div>
            </div>
            <div className={styles.item} style={{ backgroundImage: `url(${emailBg})` }}>
              <img src={email}/>
              <div className={styles.desc}>
                <div className={styles.top}>联系邮箱</div>
                <div className={styles.bottom}>zcreate-bus@zcreate.com.cn</div>
              </div>
            </div>
            <div className={styles.item} style={{ backgroundImage: `url(${addressBg})` }}>
              <img src={address}/>
              <div className={styles.desc}>
                <div className={styles.top}>联系地址</div>
                <a className={styles.bottom} onClick={handleLinkTo}>贵阳市观山湖区西部研发基地3栋11层</a>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
};

export default AboutUs;
