import React, { useEffect, useState } from "react";
import classnames from "classnames";
import styles from "./index.module.scss";
import { Tabs } from 'antd';
import scene1 from '@/assets/core/scene1.png';

const TabPane = Tabs.TabPane;

// AppScene 应用场景
const AppScene = (props) => {
    const {
        id = '',
        data = [],
        onClick = () => {},
        onChange = () => {},
        lightTheme = false
    } = props;
    const [tabKey, setTabKey] = useState(0);
    const [items, setItems] = useState([]);

    // 初始加载
    useEffect(() => {
        const list = [];
        (data || []).map((item, idx) => (
            list.push({label: item.title, key: idx})
        ));
        setItems(list);
    }, [data]);


    const handleActiveKey = (key) => {
        setTabKey(key);
    };

    return (
        <div id={id} className={classnames({
            [styles.scene]: true,
            [styles.lightTheme]: lightTheme,
        })}>
            <div className={styles.tabs}>
                <div className={styles.title}>应用场景</div>
                <Tabs activeKey={tabKey} centered items={items} size="default" onChange={(key) => handleActiveKey(key)}></Tabs>
            </div>
            <div className={styles.content}>
                <div className={styles.mid}>
                    <img src={data[tabKey].img}/>
                    <div className={styles.desc}>
                        <p>{data[tabKey].content}</p>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default AppScene;
