import React, { useEffect, useState, useRef } from "react";
import { Anchor, Animation } from '@/components';
import AppScene from '../../CoreProduct/components/AppScene';
import { Timeline, Tabs, Tooltip } from 'antd';
import classnames from "classnames";
import bg from "@/assets/icons/bg.png";
import reel from "@/assets/icons/reel.png";
import challenge from "@/assets/icons/challenge.png";
import rj_result_traffic1 from "@/assets/icons/rj_result_traffic1.png";
import rj_result_traffic2 from "@/assets/icons/rj_result_traffic2.png";
import rj_result_traffic3 from "@/assets/icons/rj_result_traffic3.png";
import program1 from "@/assets/icons/program6.png";
import program2 from "@/assets/icons/program8.png";
import program3 from "@/assets/icons/program9.png";
import program4 from "@/assets/icons/program10.png";
import solution1 from "@/assets/icons/solution1.png";
import solution2 from "@/assets/icons/solution2.png";
import solution3 from "@/assets/icons/solution3.png";
import solution4 from "@/assets/icons/solution4.png";
import resolution1 from "@/assets/icons/resolution1.png";
import resolution2 from "@/assets/icons/resolution2.png";
import resolution3 from "@/assets/icons/resolution3.png";
import resolution4 from "@/assets/icons/resolution4.png";
import suc1 from "@/assets/icons/suc3.png";
import styles from "../index.module.scss";

const { Animation1, Animation2, Animation3, Animation4, Animation5 } = Animation;
// 首页
const Home = (props) => {
  const { history } = props;
  const styleRef1 = useRef();
  const styleRef2 = useRef();
  const styleRef3 = useRef();

  const [checkedTab, setCheckedTab] = useState(0);

  // 初始加载
  useEffect(() => { }, []);

  const handleClick = (link) => {
    console.log(link);
  };
  const handleChange = (link) => {
    console.log(link);
  };
  const onChange = () => {

  }

  const sceneList = [
    {
      title: '醉驾酒驾公正执法监管',
      img: rj_result_traffic1,
      content: '执法人员进行醉驾违规改成酒驾、违规撤销酒驾、同一地点同一时间对同一辆车开具多起违法、事故人员未及时吊销驾驶证等业务违规操作风险监管。'
    },
    {
      title: '车驾管业务合规性监管',
      img: rj_result_traffic2,
      content: '业务办理人员进行驾考作弊、违规选取吉祥号、违规发放临时号牌、夜间处理非现场违法等业务违规操作风险监管。'
    },
    {
      title: '违法犯罪行为重点监管',
      img: rj_result_traffic3,
      content: '不法分子进行买卖分、事故碰瓷、报废车上路、利用已故驾驶人证照处理违法等违法行为进行监管。'
    },
  ]

  return (
    <div className={styles.homeBox}>
      <div className={styles.banner}>
        <div className={styles.bannerBox} style={{ backgroundImage: `url(${solution4})` }}>
          <div className={styles.content}>交通管理全业务监管大数据解决方案</div>
        </div>
      </div>
      <div className={styles.product}>
        <Anchor onChange={handleChange} onClick={handleClick} data={[
          {
            key: 'part-1',
            href: '#part-1',
            title: '行业背景',
          },
          {
            key: 'part-2',
            href: '#part-2',
            title: '行业痛点',
          },
          {
            key: 'part-3',
            href: '#part-3',
            title: '解决路径',
          },
          {
            key: 'part-4',
            href: '#part-4',
            title: '应用场景',
          },
          {
            key: 'part-5',
            href: '#part-5',
            title: '方案优势',
          },
          {
            key: 'part-6',
            href: '#part-6',
            title: '成功案例',
          },
        ]} />
        <div id="part-1" className={styles.cardBox} style={{ backgroundImage: `url(${bg})` }}>
          <div className={styles.title}>行业背景</div>
          <div className={styles.timeLineBox}>
            <div className={styles.boxTitle}>
              <img src={reel}></img>
              <div className={styles.boxTitleContent}>政策依据</div>
            </div>
            <div className={styles.timeLine}>
              <Timeline mode='left'>
                <Timeline.Item label="2020">
                  <div className={styles.timeLineContent}>2020年10月：公安部印发<span>《公安交通管理科技发展规划（2021年-2023年）》</span>文件明确指出，鼓励有条件的地方建设本地交通管理大数据资源池，共享对接部级平台车辆和驾驶人特征标签、风险评分等数据，协调接入更多的外部数据资源，开展大数据智能化分析研判应用。</div>
                </Timeline.Item>
                <Timeline.Item label="2019">
                  <div className={styles.timeLineContent}>2019年12月：时任公安部部长赵克志在全国公安科技信息化暨大数据智能化建设工作会议上强调，要加快数据融合渠道，打破“数据孤岛”，积极推进联通共享的大数据平台建设，加快形成覆盖全警、统筹利用的数据信息资源服务体系。</div>
                </Timeline.Item>
                <Timeline.Item label=""></Timeline.Item>
              </Timeline>
            </div>
          </div>
          <div className={styles.bottomLineBox}>
            <div className={styles.bottomLine}><span></span>在交通管理领域，均存在不同程度上的“数据孤岛”，导致数据的可用性差，融合难，难以支撑大数据在交通管理中的应用；</div>
            <div className={styles.bottomLine}><span></span>如何挖掘数据内在价值，响应政府部门简政放权、创新监管、高效服务的改革政策，加强事前事中事后的监管，成为交通管理部门亟需解决的问题。</div>
          </div>
        </div>
        <div id="part-2" className={classnames({
              [styles.cardBox]: true,
              [styles.cardBoxHover]: true,
            })} style={{ paddingBottom: '48px', backgroundColor: '#FFFFFF' }}>
          <div className={styles.title}>行业痛点</div>
          <div className={styles.challengeBox}>
            <div className={styles.challenge} ref={styleRef1}>
              <div className={styles.animationIcon}><Animation3 element={styleRef1} /></div>
              <div className={styles.challengeContent} style={{ marginTop: '10px' }}>车驾业务繁杂，生命周期长，</div>
              <div className={styles.challengeContent} style={{ marginTop: '0' }}>风险隐患多</div>
              <div className={styles.challengeContent2}>驾驶证涉及30多种业务，时间跨度长；考生来源地集中、考试积压、补考合格率过高等现象频发。机动车涉及30多种业务，时间横跨10余年；业务涉及10余个独立系统，数据分散，违规替检、虚假报废不规范行为较多。</div>
            </div>
            <div className={styles.challenge} ref={styleRef2}>
              <div className={styles.animationIcon}><Animation2 element={styleRef2} /></div>
              <div className={styles.challengeContent} style={{ marginTop: '10px' }}>违法事故业务流程多，</div>
              <div className={styles.challengeContent} style={{ marginTop: '0' }}>执法监督灰色区域隐蔽</div>
              <div className={styles.challengeContent2}>违法、事故种类多，涵盖了机动车、驾驶人、行人。不同违法行为及事故的处理流程、不同流程中存在的风险点均不同。给执法人员提供了很多滥用职权随意办理违规业务的空间。</div>
            </div>
            <div className={styles.challenge} ref={styleRef3}>
              <div className={styles.animationIcon}><Animation5 element={styleRef3} /></div>
              <div className={styles.challengeContent} style={{ marginTop: '10px' }}>人员车辆监管难，</div>
              <div className={styles.challengeContent} style={{ marginTop: '0' }}>大数据等专业手段缺乏</div>
              <div className={styles.challengeContent2}>机动车、驾驶人涉及风险隐而杂，由于大数据监测手段、数据分析能力不足，不能对机动车、驾驶人所涉及的风险隐患进行实时监测分析，无法做到及时监管。</div>
            </div>
          </div>
        </div>
        <div id="part-3" className={styles.cardBox}>
          <div className={styles.title}>解决路径</div>
          <div className={styles.solveBox}>
            <div className={styles.left} style={{backgroundImage: `url(${resolution4})`}}></div>
            <div className={styles.right}>
              <div className={styles.line2}>融合汇聚数据</div>
              <div className={styles.line3} style={{ marginBottom: '10px' }}>通过汇聚六合一系统、12123平台、集成指挥平台等数据，实时获取业务信息。</div>
              <div className={styles.line2}>构建监管模型</div>
              <div className={styles.line3} style={{ marginBottom: '10px' }}>梳理业务流程，构建机动车、驾驶人、违法、事故、驾考等业务监管模型，实现业务全监管。</div>
              <div className={styles.line2}>预警业务隐患</div>
              <div className={styles.line3} style={{ marginBottom: '10px' }}>风险实时预警，监管部门督促各级机构整改，完成闭环处置。</div>
              <div className={styles.line2}>分析预警结果</div>
              <div className={styles.line3}>对产生预警的类型、部门，根据生成时间、处置效率等不同维度进行分析研判，找到问题根因。</div>
            </div>
          </div>
        </div>
        <AppScene id={'part-4'} data={sceneList} lightTheme />
        <div id="part-5" className={styles.cardBox}>
          <div className={styles.title}>方案优势</div>
          <div className={styles.programBox}>
            <div className={styles.programBoxItem} style={{ borderRight: '0', borderBottom: '0' }}>
              <img src={program1} />
              <div className={styles.programContent}>交管业务全覆盖</div>
              <div className={styles.contentHover}>对交管所有业务进行深入监管，覆盖机动车、驾考、驾驶证、违法、事故，并提供闭环机制，将责任落实到人。</div>
            </div>
            <div className={styles.programBoxItem} style={{ borderBottom: '0' }}>
              <img src={program2} />
              <div className={styles.programContent}>研判分析可支撑</div>
              <div className={styles.contentHover}>通过构建业务标签体系，自定义业务监管模型，对异常业务进行深入研判分析。</div>
            </div>
            <div className={styles.programBoxItem} style={{ borderRight: '0' }}>
              <img src={program3} />
              <div className={styles.programContent}>重点目标强管控</div>
              <div className={styles.contentHover}>结合全息档案和关系网络，识别重点对象，对重点驾驶人、重点车辆、重点监管企业进行重点管控。</div>
            </div>
            <div className={styles.programBoxItem}>
              <img src={program4} />
              <div className={styles.programContent}>数据服务更专业</div>
              <div className={styles.contentHover}>提供专业的数据治理、数据挖掘分析服务，配备专业数据挖掘团队，帮助用户提升数据价值，更好的服务业务监管及队伍管理。</div>
            </div>
          </div>
        </div>
        <div id="part-6" className={styles.cardBox} style={{ backgroundColor: '#FFFFFF' }}>
          <div className={styles.title}>成功案例</div>
          <div className={styles.sucBox}>
            <div className={styles.sucItem} onClick={() => history.push('/home/caseDetail?sort=2')}>
              <img src={suc1} />
              <div className={styles.sucContent}>新疆交警总队公安交管大数据研判平台</div>
              <div className={styles.message}>
                <div className={styles.title}><Tooltip title="新疆交警总队公安交管大数据研判平台">新疆交警总队公安交管大数据研判平台</Tooltip></div>
                <div className={styles.text}>2019年，新疆交警总队按照“全范围覆盖、全过程记录、全数据监督”理念，建设公安交管大数据研判平台。用大数据的方法对业务数据进行融合分析、深度挖掘，结合Neo4j知识图谱技术、数据融合分析等技术，建立169+风险识别算法模型，将业务中存在的风险进行实时化、智能化、可视化、流程闭环化的监督管理。</div>
                <div className={styles.link}>
                  {"查看详情  >"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
