/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { FloatButton } from 'antd';
import { withRouter } from "react-router-dom";
import classnames from "classnames";
import { Header } from "@/components";
import styles from "./index.module.scss";
import introduce from "@/assets/footer/introduce.png";
import dianhua from "@/assets/footer/dianhua.png";
import dizhi from "@/assets/footer/dizhi.png";
import youxiang from "@/assets/footer/youxiang.png";
import qrCode from "@/assets/footer/qrCode.png";
const Home = (props) => {
  const { children, history,location } = props;
  return (
    <div className={styles.container}>
      <Header history={history} location={location}/>
      <main className={styles.body}>{children}</main>
      <footer className={styles.footerBox}>
      <div className={styles.footer}>
        <div className={styles.messageBox}>
          <img src={introduce}></img>
          <div className={styles.message}>
            <div className={styles.item}><img src={dianhua}/>0851-84828040</div>
            <div className={styles.line}></div>
            <div className={styles.item}><img src={youxiang}/>zcreate-bus@zcreate.com.cn</div>
            <div className={styles.line}></div>
            <div className={styles.item}><img src={dizhi}/>贵阳市观山湖区西部研发基地3栋11层</div>
          </div>
          <div>
          版权所有：贵州智诚科技有限公司
            <a href="http://beian.miit.gov.cn/" target="_blank" style={{marginLeft:'40px'}}>黔ICP备17009849号</a>
          </div>
        </div>
        <div className={styles.qrCode}>
          <div>关注公众号 了解更多动态</div>
          <img src={qrCode}/>
        </div>
        </div>
      </footer>
      <FloatButton.BackTop />
    </div>
  );
};

export default withRouter(Home);
