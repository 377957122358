// 获取js解析 在views模块下，只要文件夹名称为store的都默认为redux
const analysis = resolve => {
  let modules = {};
  resolve.keys().forEach(key => {
    if (key.includes(`/store/`)) {
      let exports = resolve(key).default;
      Object.assign(modules, exports);
    }
  });
  return modules;
};
let modules = analysis(require.context(`@/views/`, true, /\.js$/));

export default modules;
