import { message as Message } from 'antd';

// Interrupt异常
export class InterruptAbnormal extends Error {
  constructor(message) {
    super(message);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, InterruptAbnormal);
    }
  }

  defaultHandler = () => {
    console.error('请求取消');
  }
}


// Business异常
export class BusinessAbnormal extends Error {
  constructor(code, message, cancle = false) {
    super(message);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, BusinessAbnormal);
    }

    this.code = code * 1;
    this.cancle = cancle;
    this.defaultHandler = this.defaultHandler.bind(this);
  }

  defaultHandler = () => {
    if (!this.cancle) {
      Message.error(this.message);
    }
  }
}

// Network异常
export class NetworkAbnormal extends Error {
  constructor(code) {
    super();

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, NetworkAbnormal);
    }
    this.code = code * 1;
  }

  defaultHandler = () => {
    let content = '';
    switch (this.code) {
      case 400:
        content = '参数错误！';
        break;
      default:
        content = this.message;
    }

    Message.error(content);
  }
}
