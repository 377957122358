// import { requestErrorHandler, changeTheme } from '@/utils';

const common = {
  state: {
    bannerTextColor:'rgba(255, 255, 255, 0.65)',
    bannerBorderColor:'rgba(224, 234, 242, 0.3)',
    logoDark:false,
  },
  reducers: {
    setState(state, payload) {
      return { ...state, ...payload };
    },
  },
  effects: {

  },
};

export default common;
