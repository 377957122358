import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import rectangle from "@/assets/icons/rectangle.png";
import rectangle2 from "@/assets/icons/rectangle2.png";
import classnames from "classnames";
import styles from "./index.module.scss";

const Banner = (props) => {
  const { location, history, list = [], bannerTextColor, setState } = props;
  const [currentIndex, setCurrentIndex] = useState(0);
  const [imgScrollTop, setImgScrollTop] = useState(0);
  const [offsetHeight, setOffsetHeight] = useState(0);
  const [amnOn, setamnOn] = useState(false);
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5500,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    pauseOnHover: false,
    appendDots: (dots) => (
      <div
        style={{
          backgroundColor: "#ddd",
          borderRadius: "10px",
          padding: "10px",
        }}
      >
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        style={{
          width: "48px",
          height: "4px",
          background: "rgba(255, 255, 255, 0.2)",
        }}
      ></div>
    ),
    beforeChange: (current, next) => setCurrentIndex(next),
  };

  useEffect(() => {
    setamnOn(true);
    list[currentIndex].textColor
      ? setState({
        bannerTextColor: list[currentIndex].textColor,
        bannerBorderColor: list[currentIndex].borderColor,
        logoDark: list[currentIndex].logoDark
      })
      : setState({
        bannerTextColor: "rgba(255, 255, 255, 0.65)",
        bannerBorderColor: "rgba(224, 234, 242, 0.3)",
        logoDark: false
      });
    setTimeout(() => {
      setamnOn(false);
    }, 2000);
  }, [currentIndex]);

  useEffect(() => {
    window.addEventListener("scroll", HandleScroll, true);
    return () => {
      window.removeEventListener("scroll", HandleScroll, true);
    };
  }, []);

  const HandleScroll = () => {
    const dom = document.getElementById("swiperId");
    const offsetHeight = dom?.offsetHeight;
    const toTop = document.documentElement.scrollTop;
    setOffsetHeight(offsetHeight);
    setImgScrollTop(toTop);
  };

  const changeOriginSize = (size) => {
    const dom = document.getElementById("swiperImgId");
    dom.classList.add(styles.imgO);
  };

  const linkClick = (url) => {
    history.push(url);
  };

  return (
    <div className={styles.bannerCom} id="swiperId">
      <Slider
        {...settings}
        className="swiper-container"
        style={{ with: "100%" }}
      >
        {list.map((item, index) => {
          return (
            <div key={index}>
              <div className={classnames({[styles.selBox]: index == currentIndex, [styles.bannerBox]: true})}>
                <div className={classnames({
                    [styles.bannerImg]: true,
                    [styles.scaleOn]: index == currentIndex && !amnOn,
                    [styles.animaOn]: index == currentIndex && imgScrollTop == 0 && amnOn,
                    [styles.scaleOff]: index == !currentIndex,
                  })}
                >
                  <img src={item.imgUrl} className={classnames({
                      [styles.imgScale0]: imgScrollTop == 0,
                      [styles.imgScale20]: imgScrollTop > 0 && (imgScrollTop / offsetHeight) * 100 < 20,
                      [styles.imgScale40]: (imgScrollTop / offsetHeight) * 100 >= 20 && (imgScrollTop / offsetHeight) * 100 < 40,
                      [styles.imgScale60]: (imgScrollTop / offsetHeight) * 100 >= 40 && (imgScrollTop / offsetHeight) * 100 < 60,
                      [styles.imgScale80]: (imgScrollTop / offsetHeight) * 100 >= 60 && (imgScrollTop / offsetHeight) * 100 < 80,
                      [styles.imgScale100]: (imgScrollTop / offsetHeight) * 100 >= 80 && (imgScrollTop / offsetHeight) * 100 < 100,
                    })}
                    id="swiperImgId"
                  />
                </div>
                <div className={styles.rectangle}>
                  <img src={item.iconBlue ? rectangle2 : rectangle} />
                </div>
                <div className={styles.title} style={{ color: item?.color || "#FFFFFF", fontSize: "64px" }}>
                  {item.title}
                </div>
                <div className={styles.content} style={{ color: item?.color || "#FFFFFF", fontSize: item?.fontSize || "36px" }}>
                  {item.content}
                </div>
                <div className={styles.buttonList}>
                  {item.linkList && item.linkList.map((v, i) => {
                    return (<button key={i} onClick={() => {linkClick(v.url)}}>{v.title}</button>);
                  })}
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};
const mapStateToProps = (store) => {
  return {
    bannerTextColor: store.common.bannerTextColor,
  };
};
const mapDispatchToProps = (store) => {
  return {
    setState: store.common.setState,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Banner);
