import React, { useEffect, useState, useRef } from "react";
import { Anchor, Animation } from '@/components';
import AppScene from '../../CoreProduct/components/AppScene';
import { Timeline, Tabs, Tooltip } from 'antd';
import classnames from "classnames";
import bg from "@/assets/icons/bg.png";
import reel from "@/assets/icons/reel.png";
import challenge from "@/assets/icons/challenge.png";
import rj_result_scooter1 from "@/assets/icons/rj_result_scooter1.png";
import rj_result_scooter2 from "@/assets/icons/rj_result_scooter2.png";
import rj_result_scooter3 from "@/assets/icons/rj_result_scooter3.png";
import rj_result_scooter4 from "@/assets/icons/rj_result_scooter4.png";
import program1 from "@/assets/icons/program1.png";
import program2 from "@/assets/icons/program6.png";
import program3 from "@/assets/icons/program7.png";
import program4 from "@/assets/icons/program4.svg";
import solution1 from "@/assets/icons/solution1.png";
import solution2 from "@/assets/icons/solution2.png";
import solution3 from "@/assets/icons/solution3.png";
import solution4 from "@/assets/icons/solution4.png";
import resolution1 from "@/assets/icons/resolution1.png";
import resolution2 from "@/assets/icons/resolution2.png";
import resolution3 from "@/assets/icons/resolution3.png";
import resolution4 from "@/assets/icons/resolution4.png";
import suc1 from "@/assets/icons/suc6.png";
import styles from "../index.module.scss";

const { Animation1, Animation2, Animation3, Animation4, Animation5 } = Animation;
// 首页
const Home = (props) => {
  const { history } = props;
  const styleRef1 = useRef();
  const styleRef2 = useRef();
  const styleRef3 = useRef();

  const [checkedTab, setCheckedTab] = useState(0);

  // 初始加载
  useEffect(() => { }, []);

  const handleClick = (link) => {
    console.log(link);
  };
  const handleChange = (link) => {
    console.log(link);
  };
  const onChange = () => {

  }

  const sceneList = [
    {
      title: '车辆乱停乱放治理',
      img: rj_result_scooter1,
      content: '实现对车辆乱停乱放、超载的治理监管。'
    },
    {
      title: '车辆状态异常监测',
      img: rj_result_scooter2,
      content: '对企业超区投放、超量投放车辆状态异常监测。'
    },
    {
      title: '重点骑行轨迹跟踪',
      img: rj_result_scooter3,
      content: '通过重点骑行轨迹跟踪，实现对对僵尸车、故障车的识别治理。'
    },
    {
      title: '企业合规投放管理',
      img: rj_result_scooter4,
      content: '通过重点骑行轨迹跟踪，实现对对僵尸车、故障车的识别治理。'
    },
  ]

  return (
    <div className={styles.homeBox}>
      <div className={styles.banner}>
        <div className={styles.bannerBox} style={{ backgroundImage: `url(${solution3})` }}>
          <div className={styles.content}>共享电单车安全监管大数据解决方案</div>
        </div>
      </div>
      <div className={styles.product}>
        <Anchor onChange={handleChange} onClick={handleClick} data={[
          {
            key: 'part-1',
            href: '#part-1',
            title: '行业背景',
          },
          {
            key: 'part-2',
            href: '#part-2',
            title: '行业痛点',
          },
          {
            key: 'part-3',
            href: '#part-3',
            title: '解决路径',
          },
          {
            key: 'part-4',
            href: '#part-4',
            title: '应用场景',
          },
          {
            key: 'part-5',
            href: '#part-5',
            title: '方案优势',
          },
          {
            key: 'part-6',
            href: '#part-6',
            title: '成功案例',
          },
        ]} />
        <div id="part-1" className={styles.cardBox} style={{ backgroundImage: `url(${bg})` }}>
          <div className={styles.title}>行业背景</div>
          <div className={styles.timeLineBox}>
            <div className={styles.boxTitle}>
              <img src={reel}></img>
              <div className={styles.boxTitleContent}>政策依据</div>
            </div>
            <div className={styles.timeLine}>
              <Timeline mode='left'>
                <Timeline.Item label="2017">
                  <div className={styles.timeLineContent}>2017年8月,<span>《交通运输部等10部门关于鼓励和规范互联网租赁自行车发展的指导意见》</span>，对车辆乱停乱放、车辆运营维护不到位、企业主体责任不落实、用户资金和信息安全风险等问题提出了管理要求。</div>
                </Timeline.Item>
                <Timeline.Item label=""></Timeline.Item>
              </Timeline>
            </div>
          </div>
          <div className={styles.bottomLineBox}>
            <div className={styles.bottomLine}><span></span>2021年中国共享电单车收入规模为93.6亿元，预计2025年共享电单车的收入规模将超过200亿元；</div>
            <div className={styles.bottomLine}><span></span>随着共享电单车投放规模持续扩大，以及大众共享出行意识不断提升，共享电单车的日常使用率将进一步提高，加强共享电单车的安全监管，提升共享电单车的文明出行势在必行。</div>
          </div>
        </div>
        <div id="part-2" className={classnames({
              [styles.cardBox]: true,
              [styles.cardBoxHover]: true,
            })} style={{ paddingBottom: '48px', backgroundColor: '#FFFFFF' }}>
          <div className={styles.title}>行业痛点</div>
          <div className={styles.challengeBox}>
            <div className={styles.challenge} ref={styleRef1}>
              <div className={styles.animationIcon}><Animation1 element={styleRef1} /></div>
              <div className={styles.challengeContent}>监管手段单一，覆盖不足部门联动较差</div>
              <div className={styles.challengeContent2}>共享电单车监管模式主要以线下备案，现场执法为主，对于共享电单车的投放、运营、使用缺乏有效的监管手段，同时，监管责任部门多，各部门协调管理难度大，导致监管效率低、管理效果不显著。</div>
            </div>
            <div className={styles.challenge} ref={styleRef2}>
              <div className={styles.animationIcon}><Animation4 element={styleRef2} /></div>
              <div className={styles.challengeContent}>企业无序竞争，超区投放超量投放频发</div>
              <div className={styles.challengeContent2}>企业缺乏对车辆投放、区域划分的数据分析能力，无法合理、高效、精准的投放车辆，导致企企只能通过出现超区、超量投放等无序竞争手段来占领市场。</div>
            </div>
            <div className={styles.challenge} ref={styleRef3}>
              <div className={styles.animationIcon}><Animation2 element={styleRef3} /></div>
              <div className={styles.challengeContent}>车辆乱停乱放，影响市容市貌城市交通</div>
              <div className={styles.challengeContent2}>共享电单车乱停乱放在车站旁、禁停区、半坡中等非停放区，严重影响市容市貌和市民、车辆的正常出行。</div>
            </div>
          </div>
        </div>
        <div id="part-3" className={styles.cardBox}>
          <div className={styles.title}>解决路径</div>
          <div className={styles.solveBox}>
            <div className={styles.left} style={{backgroundImage: `url(${resolution3})`}}></div>
            <div className={styles.right}>
              <div className={styles.line2}>车辆位置实时监测</div>
              <div className={styles.line3} style={{ marginBottom: '10px' }}>结合车辆实时GPS数据、地理信息服务，结合电子围栏等手段，实时监测车辆停放情况，自动识别预警违规车辆，并推送给企业和驾驶人，进行及时处理。</div>
              <div className={styles.line2}>企业车辆动态预警</div>
              <div className={styles.line3} style={{ marginBottom: '10px' }}>利用大数据、算法模型对共享电单车的投放、停放进行实时监测、智能分析，联合GBC三端，实现数据化管理和动态监管。</div>
              <div className={styles.line2}>黑名单促文明骑行</div>
              <div className={styles.line3}>接入各车企用户信息数据，结合超区投放、站外停放等不文明行为，生成用户黑名单，规范市民安全文明出行。</div>
            </div>
          </div>
        </div>
        <AppScene id={'part-4'} data={sceneList} lightTheme />
        <div id="part-5" className={styles.cardBox}>
          <div className={styles.title}>方案优势</div>
          <div className={styles.programBox}>
            <div className={styles.programBoxItem} style={{ borderRight: '0', borderBottom: '0' }}>
              <img src={program1} />
              <div className={styles.programContent}>强化线上监管能力，丰富市民出行方式</div>
              <div className={styles.contentHover}>以信息化手段强化线上监管能力，通过监管每一辆电动车的骑行状态、停放位置等数据，实现精准化、实时化监管，解决共享单车的全程动态监测和规范治理，解决车辆乱停乱放等问题。</div>
            </div>
            <div className={styles.programBoxItem} style={{ borderBottom: '0' }}>
              <img src={program2} />
              <div className={styles.programContent}>加强部门业务协同，企业政府共管共治</div>
              <div className={styles.contentHover}>通过平台共享数据支撑业务协同，联合企业和执法主管部门共管共治，通过规范多部门的线上线下协同管理流程，实现违规行为及时发现，高效处置违规行为的目标。</div>
            </div>
            <div className={styles.programBoxItem} style={{ width: '100%' }}>
              <img src={program3} />
              <div className={styles.programContent}>引导市民文明用车，推动行业健康发展</div>
              <div className={styles.contentHover}>通过行业数据汇集实现全行业情况通盘掌控，科学有效分析区域用车需求和当前服务现状问题，合理分配车辆投放数量，为推动市民文明用车、推动行业健康发展提供有力抓手。</div>
            </div>
          </div>
        </div>
        <div id="part-6" className={styles.cardBox} style={{ backgroundColor: '#FFFFFF' }}>
          <div className={styles.title}>成功案例</div>
          <div className={styles.sucBox}>
            <div className={styles.sucItem} onClick={() => history.push('/home/caseDetail?sort=4')}>
              <img src={suc1} />
              <div className={styles.sucContent}>贵阳市共享电单车安全监管平台</div>
              <div className={styles.message}>
                <div className={styles.title}><Tooltip title="贵阳市共享电单车安全监管平台">贵阳市共享电单车安全监管平台</Tooltip></div>
                <div className={styles.text}>2022年，针对共享电单车乱停乱放、超区超量投放、僵尸车、故障车等问题，贵阳市上线运行共享电单车安全监管平台。通过对美团、青桔、小呗3家共享电单车企业数据的接入、治理、挖掘和分析，建立了车辆管理、车企管理、仓库管理、调度车辆、骑行人管理、投诉信息共6个数据资源库，构建预警模型算法21个、业务分析指标11个、考核指标25条，完成了对全市7.5万辆共享电单车的监管并预警，上线以来，日违规处置量同比下降86.37%。</div>
                <div className={styles.link}>
                  {"查看详情  >"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
