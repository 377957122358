import React, { useEffect, useState } from "react";
import classnames from "classnames";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import cooperationBanner from "@/assets/home/cooperationBanner.png";
import cooperation1 from "@/assets/home/cooperation1.png";
import cooperation2 from "@/assets/home/cooperation2.png";
import cooperation3 from "@/assets/home/cooperation3.png";
import cooperation4 from "@/assets/home/cooperation4.png";
import cooperation5 from "@/assets/home/cooperation5.png";
import styles from "./index.module.scss";
// 首页
const Home = (props) => {
  const { history } = props;
  const [checkedTab, setCheckedTab] = useState(0);

  // 初始加载
  useEffect(() => {}, []);
  const dataArr = [
    // {
    //   title: "贵阳幼儿师范高等专科学校促就业专项行动走进贵州智诚科技有限公司",
    //   detail:
    //     "为全面贯彻落实党中央国务院和省委省政府“稳就业”“保就业”决策部署，根据《省教育厅办公室关于开展全省高校书记校长访企拓岗促就业专项行动的通知》文件要求，加快健全高校毕业生市场化社会化就业创业工作机制，千方百计开拓更多就业创业岗位和机会,深入落实就业工作“一把手”工程，贵阳幼儿师范高等专科学校开展书记校长访企拓岗促就业专项行动。",
    //   img: cooperation2,
    //   link: "https://mp.weixin.qq.com/s/cIwZeXAMePPTx8GmmNVlgg",
    // },
    {
      title: "2019.12.10智诚科技与贵州理工学院大数据学院签订校企合作协议",
      detail:
        "为拓宽人才引进渠道，加强校企合作，实现校企之间相互支持、优势互补和资源共享。2019年12月10日，智诚科技与贵州理工学院大数据学院校企合作签约暨授牌仪式在智诚科技隆重举行，贵州理工学院大数据学院肖迎群院长、史明华副书记及学院师生150余人参加本次活动，智诚科技总经理沈志勇出席了校企合作签约暨授牌仪式。",
      img: cooperation4,
      link: "https://mp.weixin.qq.com/s/8lKWry_4TFilHWhMeh3cIA",
    },
    {
      title:
        "贵州智诚科技董事长徐桂林获清华-贵州大数据研究生实践教育基地2019级企业导师聘书",
      detail:
        "10月31日，高新区召开清华-贵州大数据研究生实践教育基地建设座谈会。清华大学信息学院副院长张涛、省大数据发展管理局局长马宁宇、省大数据发展管理局副局长娄松贵阳市大数据发展管理局局长吴宏春、高新区党工委副书记童祖强出席了此次会议。",
      img: cooperation3,
      link: "https://mp.weixin.qq.com/s/vFVXlZKJxhKcyacg94aaBQ",
    },
    {
      title:
        "贵州智诚科技董事长徐桂林获“上海交大-贵州专业学位研究生联合培养基地”行业导师聘书",
      detail:
        "8月22日，上海交通大学-贵州专业学位研究生联合培养基地启动仪式在贵阳举行，上海交通大学党委书记杨振斌，市委副书记、市长，贵安新区党工委副书记、管委会主任马宁宇揭牌并致辞。联培基地由上海交通大学、贵州省大数据发展管理局、贵阳国家高新技术产业开发区三方合作建立，目前主要依托上海交大电子信息与电气工程学院软件学院、感知学院，采用1+1.5的模式，开展软件工程、智能感知工程等方向的电子信息专业领域的专业学位硕士研究生培养，后续将结合贵州地方产业特点与需求，拓展专业范围，进一步深化产教融合校企联培。",
      img: cooperation1,
      link: "https://mp.weixin.qq.com/s/DAvApOp1--3FpDhgWovQdA",
    },
    {
      title: "智诚科技赴长沙理工大学校企合作",
      detail:
        " 为加强校企产学研合作与发展，强化企业人才队伍建设， 6月24日总经理沈志勇带队，副总经理张国良、杨焙等一行六人赴长沙理工大学进行为期两天的合作交流。",
      img: cooperation5,
      link: "https://mp.weixin.qq.com/s/_ET8xoA1CHFOjTSazPuJ1w",
    },
  ];
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 3,
    vertical: true,
    verticalSwiping: true,
  };
  const handleGo = (href) => {
    window.open(href);
  };
  return (
    <div className={styles.container}>
      <div className={styles.banner}>
        <img src={cooperationBanner} />
        <div className={styles.bannerTitle}>
          高校合作
          <div className={styles.bannerDesc}>
            坚持自主研发和产学研结合的道路，持续开启探索教学、
            <br />
            人才培养、技术研发等校企合作新模式。
          </div>
        </div>
      </div>
      <div className={styles.contentBox}>
        <div className={styles.swiperBox}>
          <div className={styles.arrOne}>
            <div className={styles.text}>
              <div className={styles.title}>{dataArr[0].title}</div>
              <div className={styles.detail}>{dataArr[0].detail}</div>
              <a
                className={styles.link}
                onClick={() => handleGo(dataArr[0].link)}
              >
                {"详情  >"}
              </a>
            </div>
            <div className={styles.imgBox}>
              <img src={dataArr[0].img} />
            </div>
          </div>
          <div className={styles.swiper}>
            <Slider
              {...settings}
              className="swiper-container"
              style={{ with: "100%" }}
            >
              {dataArr.splice(1).map((item, index) => {
                return (
                  <div key={index}>
                    <div
                      style={{ backgroundImage: `url(${item?.img})` }}
                      className={styles.rightBox}
                    >
                      <div className={styles.message}>
                        <div className={styles.title}>{item.title}</div>
                        <div className={styles.text}>{item.detail}</div>
                        <div
                          className={styles.link}
                          onClick={() => handleGo(item.link)}
                        >
                          {"查看详情  >"}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
