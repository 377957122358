import React, { useRef } from "react";
import {Anchor, Animation} from '@/components'
import AppScene from '../components/AppScene'
import styles from "./index.module.scss";
import banner from 'assets/core/banner1.jpg';
import scene1 from '@/assets/core/scene1.png';
import scene2 from '@/assets/core/scene2.png';
import scene3 from '@/assets/core/scene3.png';
import scene4 from '@/assets/core/scene4.png';
import frame1 from '@/assets/core/framework1.png';
import func1 from '@/assets/core/tocc_func1.png';
import func2 from '@/assets/core/tocc_func2.png';
import func3 from '@/assets/core/tocc_func3.png';
import func4 from '@/assets/core/tocc_func4.png';
import func5 from '@/assets/core/tocc_func5.png';

const { Animation1, Animation2, Animation3, Animation4, Animation5 } = Animation;

// TOCC
const Tocc = () => {

    const styleRef1 = useRef();
    const styleRef2 = useRef();
    const styleRef3 = useRef();
    const styleRef4 = useRef();
    const styleRef5 = useRef();


    const handleClick = (link) => {
        // console.log(link);
    };
    const handleChange = (link) => {
        // console.log(link);
    };

    const anchorList = [
        {
            key: 'module-1',
            href: '#module-1',
            title: '产品介绍',
        },
        {
            key: 'module-2',
            href: '#module-2',
            title: '应用场景',
        },
        {
            key: 'module-3',
            href: '#module-3',
            title: '产品功能',
        },
        {
            key: 'module-4',
            href: '#module-4',
            title: '产品优势',
        },
    ];

    const sceneList = [
        {
            title: '车辆监测',
            img: scene1,
            content: '重点车辆实时监测，风险隐患及时管控。'
        },
        {
            title: '场站监测',
            img: scene2,
            content: '重要场站实时监测，协同调度运力资源。'
        },
        {
            title: '道路监测',
            img: scene3,
            content: '监测道路交通事故，应急指挥联动调度。'
        },
        {
            title: '公交监测',
            img: scene4,
            content: '建立公交监测体系，规划线路接驳优化。'
        },
    ]
    return (
        <div className={styles.container}>
            <div className={styles.banner}>
                <img src={banner}/>
                <div className={styles.desc}>智慧交通平台（TOCC）</div>
            </div>
            <Anchor data={anchorList} onChange={handleChange} onClick={handleClick}/>

            <div id="module-1" className={styles.products}>
                <div className={styles.title}>产品介绍</div>
                <div className={styles.content}>面向交通运输行业，为政府、企业提供营运车辆安全监管、综合交通运行监测、交通事件应急指挥、综合规划决策能力，为公众提供交通出行服务，实现城市交通大数据的综合应用。</div>
            </div>

            <AppScene id={'module-2'} data={sceneList} />

            <div id="module-3" className={styles.function}>
                <div className={styles.title}>产品功能</div>
                <div className={styles.mid}>
                    <div className={styles.content}>
                        <img src={func1}/>
                        <div className={styles.right}>
                            <div className={styles.head}>运输安全监管</div>
                            <div className={styles.main}>构建监测体系，对城市道路、公交、地铁、出租车/网约车、共享单车、两客一危、重货运输、航空、铁路、客运站等进行全路网、全车网、行业专项、客流分布的监测预警管理。</div>
                        </div>
                    </div>
                    <div className={styles.content}>
                        <img src={func2}/>
                        <div className={styles.right}>
                            <div className={styles.head}>运力协同调度</div>
                            <div className={styles.main}>对重点场站、重点区域的出租、公交、轨道运力进行实时监测，针对运力不足、运力过剩进行监测预警，及时进行运力事件的处置，实现运力协同调度。</div>
                        </div>
                    </div>
                    <div className={styles.content}>
                        <img src={func3}/>
                        <div className={styles.right}>
                            <div className={styles.head}>应急指挥</div>
                            <div className={styles.main}>对应急事件进行监测预警，通过应急值守和应急资源的管理，实现对应急事件的全流程处置和应急调度通信下发。</div>
                        </div>
                    </div>
                    <div className={styles.content}>
                        <img src={func4}/>
                        <div className={styles.right}>
                            <div className={styles.head}>综合规划决策</div>
                            <div className={styles.main}>分析交通运行指数，对道路拥堵进行研判，对交通流及车辆OD进行影响分析，对城市交通运行规划提供决策辅助。</div>
                        </div>
                    </div>
                    <div className={styles.content}>
                        <img src={func5}/>
                        <div className={styles.right}>
                            <div className={styles.head}>交通信息服务</div>
                            <div className={styles.main}>下发交通信息，提供实时路况、实时出租、实时公交、实时地铁、实时电单车等综合交通便民服务。</div>
                        </div>
                    </div>
                </div>
                <div className={styles.iframe}>
                    <img src={frame1}/>
                </div>
            </div>

            <div id="module-4" className={styles.superiority}>
                <div className={styles.title}>产品优势</div>
                <div className={styles.bottom}>
                    <div className={styles.cont} ref={styleRef1}>
                        <div className={styles.ani}>
                            <Animation1 element={styleRef1} />
                        </div>
                        <div className={styles.name}>多维度，多场景的<br/>数据汇聚、治理、分析</div>
                        <div className={styles.desc}>通过自研的大数据基础平台，实现对交通行业数据的汇聚、开发、治理、挖掘，全面的数据服务保障业务稳定运行。</div>
                    </div>
                    <div className={styles.cont} ref={styleRef2}>
                        <div className={styles.ani}>
                            <Animation2 element={styleRef2}  />
                        </div>
                        <div className={styles.name}>多指标精细化监管<br/>实时动态监测及预警</div>
                        <div className={styles.desc}>构建监测体系，对全路网、全车网、客流、行业、进行指标监测预警，对两客一危、公交、出租、共享单车进行运输监管，量化行业运行态势，直观体现行业特征，把握城市运输运行细节。</div>
                    </div>
                    <div className={styles.cont} ref={styleRef3}>
                        <div className={styles.ani}>
                            <Animation3 element={styleRef3}  />
                        </div>
                        <div className={styles.name}>多场景的交通运力监测<br/>及指挥调度</div>
                        <div className={styles.desc}>监测重点场站、重点区域的出租、公交、轨道运力情况，通过算法模型预测分析运力，及时进行运力指挥调度。</div>
                    </div>
                    <div className={styles.cont} ref={styleRef4}>
                        <div className={styles.ani}>
                            <Animation4 element={styleRef4}  />
                        </div>
                        <div className={styles.name}>综合交通规划决策辅助</div>
                        <div className={styles.desc}>对交通运行综合分析、交通路网优化调整决策分析、公共交通优化决策分析、交通行业经济发展分析，为交通优化提供决策支持。</div>
                    </div>
                    <div className={styles.cont} ref={styleRef5}>
                        <div className={styles.ani}>
                            <Animation5 element={styleRef5}  />
                        </div>
                        <div className={styles.name}>交通信息发布<br/>及综合交通便民服务</div>
                        <div className={styles.desc}>以综合交通信息发布，小程序、App为载体，面向公众提供实时路况、实时出租、实时公交、实时地铁、实时电单车等便民服务。</div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Tocc;
