/**
 * 路由主组件
 */
import React from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import history from "@/utils/history";
import { AsyncComponent, InitPage } from "@/components";
// 首页
const Home = AsyncComponent(() => import("@/views/Home"));
// 关于我们
const AboutUs = AsyncComponent(() => import("@/views/AboutUs"));
// 核心产品
const Tocc = AsyncComponent(() => import("@/views/CoreProduct/Tocc"));
const Vehicle = AsyncComponent(() => import("@/views/CoreProduct/Vehicle"));
const Judge = AsyncComponent(() => import("@/views/CoreProduct/Judge"));
const Data = AsyncComponent(() => import("@/views/CoreProduct/Data"));
// 解决方案
const SolutionCity = AsyncComponent(() => import("@/views/Solution/city"));
const SolutionLkyw = AsyncComponent(() => import("@/views/Solution/lkyw"));
const SolutionScooter = AsyncComponent(() => import("@/views/Solution/scooter"));
const SolutionTraffic = AsyncComponent(() => import("@/views/Solution/traffic"));
// 数据服务
const DataService = AsyncComponent(() => import("@/views/DataService/index"));
// 高校合作
const Cooperation = AsyncComponent(() => import("@/views/Cooperation"));
// 首页案例详情页
const CaseDetail = AsyncComponent(() => import("@/views/CaseDetail"));

const routerData = [
  { path: "/home", component: Home },
  { path: "/home/caseDetail", component: CaseDetail },
  { path: "/aboutUs", component: AboutUs },
  { path: "/coreProduct/tocc", component: Tocc },
  { path: "/coreProduct/vehicle", component: Vehicle },
  { path: "/coreProduct/judge", component: Judge },
  { path: "/coreProduct/data", component: Data },
  { path: "/solution/city", component: SolutionCity },
  { path: "/solution/lkyw", component: SolutionLkyw },
  { path: "/solution/scooter", component: SolutionScooter },
  { path: "/solution/traffic", component: SolutionTraffic },
  { path: "/dataService", component: DataService },
  { path: "/cooperation", component: Cooperation },
];
const RouterCom = (props) => {
  return (
    <Router history={history}>
      <Switch>
        <InitPage>
          <Route exact path="/" render={() => <Redirect to="/home" />} />
          {routerData.map((item) => {
            return (
              <Route
                exact
                path={item.path}
                key={item.path}
                component={item.component}
              />
            );
          })}
        </InitPage>
      </Switch>
    </Router>
  );
};

export default RouterCom;
