import React, {useEffect,useState,useRef} from "react";
import {Anchor, Animation} from '@/components'
import { useScroll } from '@/utils';
import AppScene from '../components/AppScene'
import styles from "./index.module.scss";
import banner from 'assets/core/banner4.jpg';
import scene13 from '@/assets/core/scene13.png';
import scene14 from '@/assets/core/scene14.png';
import frame4 from '@/assets/core/framework4.png';
import func1 from '@/assets/core/data_func1.png';
import func2 from '@/assets/core/data_func2.png';
import func3 from '@/assets/core/data_func3.png';
import func4 from '@/assets/core/data_func4.png';

const { Animation1, Animation2, Animation3 } = Animation;

// 大数据基础平台
const Data = () => {
    const styleRef1 = useRef();
    const styleRef2 = useRef();
    const styleRef3 = useRef();

    const handleClick = (link) => {
        // console.log(link);
    };
    const handleChange = (link) => {
        // console.log(link);
    };

    const anchorList = [
        {
            key: 'module-1',
            href: '#module-1',
            title: '产品介绍',
        },
        {
            key: 'module-2',
            href: '#module-2',
            title: '应用场景',
        },
        {
            key: 'module-3',
            href: '#module-3',
            title: '产品功能',
        },
        {
            key: 'module-4',
            href: '#module-4',
            title: '产品优势',
        },
    ]

    const sceneList = [
        {
            title: '智慧交通',
            img: scene13,
            content: '交通运输业务繁多，如公交、出租、航空、铁路等业务，每个细分业务存在数据类型多、数据量大、数据质量差、数据资产混乱等问题，导致数据无法正确高效的支撑业务运行。针对这些问题，大数据基础平台提供数据采集、数据开发、数据治理、数据共享等功能业务，对多源数据的汇聚、分析、治理和共享提供一站式管理，支撑智慧交通业务的数据接入分析和数据资源管理，从而提升数据质量，保障业务的正常运行。'
        },
        {
            title: '智慧交管',
            img: scene14,
            content: '交通管理类的人、车、企、违法、执法、设备设施等数据繁杂，数据资源缺乏统一的管理，各个业务数据的汇聚、分析、开发难以实现，对于监管部门来说，数据的价值难以发挥出来，不能用数据支撑业务运行。针对这些问题，大数据基础平台提供数据采集、数据开发、数据治理、数据共享等功能业务，对交管类数据进入统一的汇聚、分析、治理和共享，支撑智慧交管业务的数据分析和挖掘，保障数据的可用性，从而保障数据对上层应用的有力支撑。'
        },
    ]

    const { currentLevel } = useScroll({grade: 3});
    return (
        <div className={styles.container}>
            <div className={styles.banner}>
                <img src={banner}/>
                <div className={styles.desc}>大数据基础平台</div>
            </div>

            <Anchor data={anchorList} onChange={handleChange} onClick={handleClick}/>

            <div id="module-1" className={styles.products}>
                <div className={styles.title}>产品介绍</div>
                <div className={styles.content}>自主研发大数据管理平台，覆盖数据资源管理、数据质量运维、数据可视化、数据分析挖掘四大产品能力，支撑交通运输和交通管理两大数据资源服务。</div>
            </div>

            <AppScene id={'module-2'} data={sceneList} />

            <div id="module-3" className={styles.function}>
                <div className={styles.title}>产品功能</div>
                <div className={styles.mid}>
                    <div className={styles.content}>
                        <img src={func1}/>
                        <div className={styles.right}>
                            <div className={styles.head}>大数据资源管理平台<div className={styles.label}>Data S</div></div>
                            <div className={styles.main}>支持多种采集协议，实现海量数据的融合与汇聚、数据综合治理、数据共享与开放。</div>
                        </div>
                    </div>
                    <div className={styles.content}>
                        <img src={func2}/>
                        <div className={styles.right}>
                            <div className={styles.head}>大数据可视化平台<div className={styles.label}>Data A</div></div>
                            <div className={styles.main}>提供快速构建数据分析可视化图表的业务能力，让行业用户围绕业务主题展开快速可视化设计工作。</div>
                        </div>
                    </div>
                    <div className={styles.content}>
                        <img src={func3}/>
                        <div className={styles.right}>
                            <div className={styles.head}>大数据挖掘平台<div className={styles.label}>Data M</div></div>
                            <div className={styles.main}>提供一站式的开发流程、可视化的建模环境、丰富的算法组件和高效的并行计算等功能服务，使得开发人员能够高效、便捷的完成分析挖掘工作。</div>
                        </div>
                    </div>
                    <div className={styles.content}>
                        <img src={func4}/>
                        <div className={styles.right}>
                            <div className={styles.head}>大数据质量运维平台<div className={styles.label}>Data O</div></div>
                            <div className={styles.main}>提供设备运行监测、故障预警、资产设备全生命周期管理能力，基于算法建模分析数据，实现对设备和业务数据的质量监控及运维。</div>
                        </div>
                    </div>
                </div>
                <div className={styles.iframe}>
                    <img src={frame4}/>
                </div>
            </div>

            <div id="module-4" className={styles.superiority}>
                <div className={styles.title}>产品优势</div>
                <div className={styles.bottom}>
                    <div className={styles.cont} ref={styleRef1}>
                        <div className={styles.ani}>
                            <Animation3 element={styleRef1} />
                        </div>
                        <div className={styles.name}>高组件化按需配置</div>
                        <div className={styles.desc}>平台多版本、高度组件化的架构，可根据用户的业务需求快速搭配、快速组合，搭建满足用户需求的业务平台。</div>
                    </div>
                    <div className={styles.cont} ref={styleRef2}>
                        <div className={styles.ani}>
                            <Animation1 element={styleRef2} />
                        </div>
                        <div className={styles.name}>行业通用推动变革</div>
                        <div className={styles.desc}>可面向多个行业、不同细分领域提供数据业务应用，推动以数据为基础的业务变革。</div>
                    </div>
                    <div className={styles.cont} ref={styleRef3}>
                        <div className={styles.ani}>
                            <Animation2 element={styleRef3} />
                        </div>
                        <div className={styles.name}>完备业务快速支撑</div>
                        <div className={styles.desc}>平台具备数据汇聚、开发、治理、分析、服务、挖掘等整套大数据开发治理业务，一站式响应业务需求。</div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Data;
