import {useEffect, useRef, useState} from 'react';

function useScroll(props) {
  const {
    grade,
  } = props;

  const [currentLevel, setCurrentLevel] = useState(1); //currentLevel表示目前当前滚动，初始为1
  const [isBottom, setIsBottom] = useState(false); //是否到底部
  const currentLevelRef = useRef(null);
  const isBottomRef = useRef(null);

  useEffect(() => {
    if(currentLevel == 1){
      window.addEventListener('scroll', handleScroll)
    }
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, []);

  useEffect(() => {
    currentLevelRef.current = currentLevel;
    isBottomRef.current = isBottom;
  }, [currentLevel,isBottom]);


  const handleScroll= (event) => {
    // 滚动的高度
    const scrollTop = event.srcElement ? event.srcElement.documentElement.scrollTop : 0;
    //可视区域的高度
    const clientHeight = event.srcElement ? event.srcElement.documentElement.clientHeight : 0;
    //滚动条内容的总高度
    const scrollHeight = event.srcElement ? event.srcElement.documentElement.scrollHeight : 0;

    const _nodata=isBottomRef.current;
    if ((scrollTop + clientHeight >= scrollHeight - 288) && !_nodata) {
      const newLevel = currentLevelRef.current;
      if(newLevel < grade){
        setCurrentLevel(prev => prev + 1);
      }else{
        setIsBottom(true);
      }
    }
  }

  return {
    currentLevel,
  };
}
export default useScroll;