import React from "react";
import styles from "./index.module.scss";
// 首页
const TitleBox = (props) => {
  const { name, conArr,style } = props;
  return (
    <div className={styles.message} style={style}>
      <h1>{name}</h1>
      <div className={styles.recommend}>
        {conArr.map((v, i) => (
          <div key={i}>{v}</div>
        ))}
      </div>
    </div>
  );
};

export default TitleBox;
