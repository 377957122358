import React, { useEffect } from "react";
import { Anchor } from 'antd';
import styles from "./index.module.scss";

// Anchor
const newAnchor = (props) => {
    const {
        data = [],
        onClick = () => {},
        onChange = () => {},
    } = props;
  // 初始加载
    useEffect(() => {}, []);

    const handleClick = (e, link) => {
        e.preventDefault();
        onClick(link);
    };

    return (
        <div className={styles.anchor}>
              <Anchor direction="horizontal" onClick={handleClick} onChange={onChange} items={data} />
        </div>
    )
};

export default newAnchor;
