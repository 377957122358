import React, { useEffect, useState, useRef } from "react";
import { Anchor, Animation } from '@/components';
import AppScene from '../../CoreProduct/components/AppScene';
import { Timeline, Tabs, Tooltip } from 'antd';
import classnames from "classnames";
import bg from "@/assets/icons/bg.png";
import reel from "@/assets/icons/reel.png";
import challenge from "@/assets/icons/challenge.png";
import rj_result_lkyw1 from "@/assets/icons/rj_result_lkyw1.png";
import rj_result_lkyw2 from "@/assets/icons/rj_result_lkyw2.png";
import rj_result_lkyw3 from "@/assets/icons/rj_result_lkyw3.png";
import rj_result_lkyw4 from "@/assets/icons/rj_result_lkyw4.png";
import rj_result_lkyw5 from "@/assets/icons/rj_result_lkyw5.png";
import program1 from "@/assets/icons/program1.png";
import program2 from "@/assets/icons/program2.png";
import program3 from "@/assets/icons/program3.png";
import program4 from "@/assets/icons/program4.png";
import program5 from "@/assets/icons/program5.png";
import solution1 from "@/assets/icons/solution1.png";
import solution2 from "@/assets/icons/solution2.png";
import solution3 from "@/assets/icons/solution3.png";
import solution4 from "@/assets/icons/solution4.png";
import resolution1 from "@/assets/icons/resolution1.png";
import resolution2 from "@/assets/icons/resolution2.png";
import resolution3 from "@/assets/icons/resolution3.png";
import resolution4 from "@/assets/icons/resolution4.png";
import suc1 from "@/assets/icons/suc5.png";
import styles from "../index.module.scss";

const { Animation1, Animation2, Animation3, Animation4, Animation5 } = Animation;
// 首页
const Home = (props) => {
  const { history } = props;
  const styleRef1 = useRef();
  const styleRef2 = useRef();
  const styleRef3 = useRef();
  const styleRef4 = useRef();

  const [checkedTab, setCheckedTab] = useState(0);

  // 初始加载
  useEffect(() => { }, []);

  const handleClick = (link) => {
    console.log(link);
  };
  const handleChange = (link) => {
    console.log(link);
  };
  const onChange = () => {

  }

  const sceneList = [
    {
      title: '危险驾驶状态识别',
      img: rj_result_lkyw1,
      content: '对驾驶人疲劳驾驶、分神、抽烟、打电话、不系安全带、双手脱离方向盘、从业资格证过期等风险隐患进行识别。'
    },
    {
      title: '车辆安全行驶监测',
      img: rj_result_lkyw2,
      content: '对车辆前向碰撞告警、车道偏离、车距过境、车辆年检年逾期审隐患进行识别。'
    },
    {
      title: '证照是否有效验证',
      img: rj_result_lkyw3,
      content: '对企业未购置承运人责任险、承运人责任险过期、车辆数不满足要求等隐患进行识别。'
    },
    {
      title: '运输过程实时监督',
      img: rj_result_lkyw5,
      content: '对运输过程中运输货物不符、运输路线偏移、运输时间不匹配等情况进行实时监督。'
    },
    {
      title: '车载设备异常监测',
      img: rj_result_lkyw4,
      content: '对GPS设备异常、ADAS设备异常、DSM设备异常隐患进行识别。'
    },
  ]

  return (
    <div className={styles.homeBox}>
      <div className={styles.banner}>
        <div className={styles.bannerBox} style={{ backgroundImage: `url(${solution2})` }}>
          <div className={styles.content}>两客一危安全监管大数据解决方案</div>
        </div>
      </div>
      <div className={styles.product}>
        <Anchor onChange={handleChange} onClick={handleClick} data={[
          {
            key: 'part-1',
            href: '#part-1',
            title: '行业背景',
          },
          {
            key: 'part-2',
            href: '#part-2',
            title: '行业痛点',
          },
          {
            key: 'part-3',
            href: '#part-3',
            title: '解决路径',
          },
          {
            key: 'part-4',
            href: '#part-4',
            title: '应用场景',
          },
          {
            key: 'part-5',
            href: '#part-5',
            title: '方案优势',
          },
          {
            key: 'part-6',
            href: '#part-6',
            title: '成功案例',
          },
        ]} />
        <div id="part-1" className={styles.cardBox} style={{ backgroundImage: `url(${bg})` }}>
          <div className={styles.title}>行业背景</div>
          <div className={styles.timeLineBox}>
            <div className={styles.boxTitle}>
              <img src={reel}></img>
              <div className={styles.boxTitleContent}>政策依据</div>
            </div>
            <div className={styles.timeLine}>
              <Timeline mode='left'>
                <Timeline.Item label="2014">
                  <div className={styles.timeLineContent}>2014年7月1日，交通运输部颁布施行<span>《道路运输车辆动态监督管理办法》</span>，规定公路营运的载客汽车、危险货物运输车辆须安装卫星定位装置，并接入全国重点营运车辆联网联控系统。对未按照要求安装卫星定位装置，或者已安装但未能在联网联控系统正常显示的车辆，不予发放或者审验《道路运输证件》。</div>
                </Timeline.Item>
                <Timeline.Item label=""></Timeline.Item>
              </Timeline>
            </div>
          </div>
          <div className={styles.bottomLineBox}>
            <div className={styles.bottomLine}><span></span>营运车中的客运、危险品运输车辆，事故发生所带来的后果严重、社会影响大、经济损失多；</div>
            <div className={styles.bottomLine}><span></span>车辆风险隐患多而杂，加之监管手段缺乏，监管部门难以精细化监管，对风险隐患无法做到事前提前预防、事中高效监督；</div>
            <div className={styles.bottomLine}><span></span>行业信息化建设的重点应放在对车辆驾驶员的违规操作、行车过程的监管上，实现风险提前预防、事中实时监管、事后及时处理，从而有效遏制交通事故，保障交通安全。</div>
          </div>
        </div>
        <div id="part-2" className={classnames({
              [styles.cardBox]: true,
              [styles.cardBoxHover]: true,
            })} style={{ paddingBottom: '48px', backgroundColor: '#FFFFFF' }}>
          <div className={styles.title}>行业痛点</div>
          <div className={styles.challengeBox}>
            <div className={styles.challenge} ref={styleRef1}>
              <div className={styles.animationIcon}><Animation1 element={styleRef1} /></div>
              <div className={styles.challengeContent}>监管对象不合理</div>
              <div className={styles.challengeContent2}>当前监管模式主要是对企业的监管，对于车辆和驾驶人的安全行驶，依托于各类监管平台，平台烟囱耸立建而不用，造成行业的全面监管不到位。</div>
            </div>
            <div className={styles.challenge} ref={styleRef2}>
              <div className={styles.animationIcon}><Animation4 element={styleRef2} /></div>
              <div className={styles.challengeContent}>使用数据来源少</div>
              <div className={styles.challengeContent2}>已搭建的各类平台，以车辆位置数据为主，缺乏车辆行驶过程中的安全报警数据、运政的人车户数据，数据融合不够，对风险研判的支撑较弱，风险预测不全面。</div>
            </div>
            <div className={styles.challenge} ref={styleRef3}>
              <div className={styles.animationIcon}><Animation5 element={styleRef3} /></div>
              <div className={styles.challengeContent}>风险隐患不闭环</div>
              <div className={styles.challengeContent2}>以企业为主导，没有联动行业管理部门和驾驶人，导致行业管理部门、企业以及驾驶人三方信息不互通。</div>
            </div>
          </div>
          <div className={classnames({
              [styles.challengeBox]: true,
            })} style={{ marginTop: 0 }}>
            <div className={styles.challenge} ref={styleRef4}>
              <div className={styles.animationIcon}><Animation3 element={styleRef4} /></div>
              <div className={styles.challengeContent}>处置流程效率低</div>
              <div className={styles.challengeContent2}>监管部门人员编制不足，不能对行业的每一家企业、车辆、从业人员进行实时监管。通常对企业进行随机抽查，驾驶人和车辆进行重点路段布控等传统手段进行管理，效率低效果差。</div>
            </div>
            <div className={styles.challenge} style={{ opacity: 0, cursor: 'default' }}></div>
            <div className={styles.challenge}  style={{ opacity: 0, cursor: 'default' }}></div>
          </div>
        </div>
        <div id="part-3" className={styles.cardBox}>
          <div className={styles.title}>解决路径</div>
          <div className={styles.solveBox}>
            <div className={styles.left} style={{backgroundImage: `url(${resolution2})`}}></div>
            <div className={styles.right}>
              <div className={styles.line2}>融合数据</div>
              <div className={styles.line3} style={{ marginBottom: '10px' }}>汇聚政府证照、档案、企业运营、运输车辆、运输人员等全量数据，建设统一的数据平台，实现数据资源的统一存储、统一管理和高度共享。</div>
              <div className={styles.line2}>建立风险隐患模型</div>
              <div className={styles.line3} style={{ marginBottom: '10px' }}>针对两客一危建立风险模型和隐患模型，自动识别驾驶人、机动车、企业存在的风险隐患。</div>
              <div className={styles.line2}>形成闭环管理</div>
              <div className={styles.line3} style={{ marginBottom: '10px' }}>基于政府、企业、从业人员三端实现针对风险隐患处理的监管闭环管理。</div>
              <div className={styles.line2}>构建信用体系</div>
              <div className={styles.line3}>构建道路运输行业企业信用、驾驶人信用评级评价体系。</div>
              <div className={styles.line2} style={{ marginBottom: '10px' }}>服务运营</div>
              <div className={styles.line3}>配备专业的数据治理、数据挖掘分析大数据服务团队，帮助用户提升数据价值，为用户提供定制化服务及数据报表，保障决策制定高效可行。</div>
            </div>
          </div>
        </div>
        <AppScene id={'part-4'} data={sceneList} lightTheme />
        <div id="part-5" className={styles.cardBox}>
          <div className={styles.title}>方案优势</div>
          <div className={styles.programBox}>
            <div className={styles.programBoxItem} style={{ borderRight: '0', borderBottom: '0' }}>
              <img src={program1} />
              <div className={styles.programContent}>监管对象完整覆盖，深化行业管理运营</div>
              <div className={styles.contentHover}>结合大数据手段，实现两客一危车辆、驾驶人、企业的营运过程全面监管。</div>
            </div>
            <div className={styles.programBoxItem} style={{ borderBottom: '0' }}>
              <img src={program2} />
              <div className={styles.programContent}>风险隐患及时发现，减少运输安全问题</div>
              <div className={styles.contentHover}>构建60+风险隐患模型，实现风险隐患自动化监测预警，保障运输安全。</div>
            </div>
            <div className={styles.programBoxItem} style={{ borderRight: '0', borderBottom: '0' }}>
              <img src={program3} />
              <div className={styles.programContent}>业务流程处置闭环，优化政企业务联动</div>
              <div className={styles.contentHover}>构建GBC三端，形成政府风险管控、企业风险处置、驾驶人风险反馈的“三端闭环”管理。</div>
            </div>
            <div className={styles.programBoxItem} style={{ borderBottom: '0' }}>
              <img src={program4} />
              <div className={styles.programContent}>信用体系评级评价，创新运输行业监管</div>
              <div className={styles.contentHover}>多维度分析企业、驾驶人安全营运行为，实现重点对象重点管控。</div>
            </div>
            <div className={styles.programBoxItem} style={{ width: '100%' }}>
              <img src={program5} />
              <div className={styles.programContent}>运营服务辅助监管，提升政府管理效能</div>
              <div className={styles.contentHover}>配备专业的大数据服务团队，提供高效、专业、定制化服务。</div>
            </div>
          </div>
        </div>
        <div id="part-6" className={styles.cardBox} style={{ backgroundColor: '#FFFFFF' }}>
          <div className={styles.title}>成功案例</div>
          <div className={styles.sucBox}>
            <div className={styles.sucItem} onClick={() => history.push('/home/caseDetail?sort=1')}>
              <img src={suc1} />
              <div className={styles.sucContent}>浙江省营运车第三方检测服务平台</div>
              <div className={styles.message}>
                <div className={styles.title}><Tooltip title="浙江省营运车第三方检测服务平台">浙江省营运车第三方检测服务平台</Tooltip></div>
                <div className={styles.text}>2019年，浙江省交通厅建设浙江省营运车第三方安全监测服务平台，平台以“两客一危”车辆监测为主，逐步覆盖监测全省营运车辆。通过获取实时、海量、多维的“人、车、企、客、货、路”数据，敏锐地感知车辆安全风险，精准地优化企业的运营管理，提升政府监管效率，降低政府监管压力，有效指导相关企业进行优化整改。</div>
                <div className={styles.link}>
                  {"查看详情  >"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
