import React, { useEffect, useState } from "react";
import { Empty } from "antd";
import caseDetail0 from "@/assets/home/CaseDetail/caseDetail0.png";
import caseDetail1 from "@/assets/home/CaseDetail/caseDetail1.png";
import caseDetail2 from "@/assets/home/CaseDetail/caseDetail2.png";
import caseDetail3 from "@/assets/home/CaseDetail/caseDetail3.png";
import caseDetail4 from "@/assets/home/CaseDetail/caseDetail4.png";
import caseDetail5 from "@/assets/home/CaseDetail/caseDetail5.png";
import caseDetail6 from "@/assets/home/CaseDetail/caseDetail6.png";
import caseDetail7 from "@/assets/home/CaseDetail/caseDetail7.png";
import tocc from "@/assets/home/CaseDetail/tocc.png";
import yingyunche from "@/assets/home/CaseDetail/yingyunche.png";
import yanpan from "@/assets/home/CaseDetail/yanpan.png";
import guiyixing from "@/assets/home/CaseDetail/guiyixing.png";
import diandanche from "@/assets/home/CaseDetail/diandanche.png";
import zhihuijiaotong from "@/assets/home/CaseDetail/zhihuijiaotong.png";
import jiaoguan from "@/assets/home/CaseDetail/jiaoguan.png";
import yanpan2 from "@/assets/home/CaseDetail/yanpan2.png";
import styles from "./index.module.scss";
// 首页
const Home = (props) => {
  const { history, location } = props;
  const [checked, setChecked] = useState();

  // 初始加载
  useEffect(() => {
    setChecked(location.search.split("=")[1]);
  }, [location]);
  const dataArr = [
    {
      name: "贵阳市智慧交通平台（TOCC）",
      img: caseDetail0,
      banner: tocc,
      children: [
        {
          title: "· 平台建设启动",
          text: "2020年，贵阳市交通委员会全面启动贵阳市智慧交通平台的建设，按“一中心、四平台、五项应用”的整体架构完成了数据平台和各类功能模块的建设，实现了交通领域人、车、路、行为等大数据资源全面汇聚和全市交通运行状况的实时监测与分析，推动全市交通大数据资源的整合与纵横向开放共享。",
        },
        {
          title: "· 平台上线运行",
          text: "平台上线后，接入全市公交、出租、网约车、停车场、航空、铁路、汽车维修、手机信令、轨道交通、电警卡口、两客一危GPS、联网售票、运政、机场大巴、旅游景区等10多个行业管理部门、40多业务系统，累计734.3亿条数据。通过对数据的分析和深入挖掘，实现对贵阳市道路通行指数、场站客流情况、公交出租态势实时分析监测。同时，提供道路拥堵指数计算、节假日交通流量预测、重要场站客运运力调度、非法运营车识别等数据分析服务，帮助交通管理部门实时监测城市交通运行情况，并及时发现问题、解决问题，真正让数据的价值和应用辅助决策。",
        },
      ],
    },
    {
      name: "浙江省营运车第三方检测服务平台",
      img: caseDetail1,
      banner: yingyunche,
      children: [
        {
          title: "· 平台建设启动",
          text: "2019年，浙江省交通厅建设浙江省营运车第三方安全监测服务平台，平台以“两客一危”车辆监测为主，逐步覆盖监测全省营运车辆。通过获取实时、海量、多维的“人、车、企、客、货、路”数据，敏锐地感知车辆安全风险，精准地优化企业的运营管理，提升政府监管效率，降低政府监管压力，有效指导相关企业进行优化整改。",
        },
        {
          title: "· 平台上线运行",
          text: "平台于2019年5月上线，接入1373家“两客一危”企业，车辆1.9万余辆，驾驶员6千余人。针对驾驶人、设备、车辆，按照行为异常、疲劳驾驶、超速驾驶、超时驾驶、凌晨禁行、设备异常、行驶异常等7大类型共计监测到150万余起风险。上线运行以来，平台监管到的重大风险环比下降9.32%，风险处置率上升到76.9%；车辆安全行驶里程达1343万公里，安全行驶时长达38.1万小时，有力保障了全省道路运输安全的良好态势。",
        },
      ],
    },
    {
      name: "新疆交警总队公安交管大数据研判平台",
      img: caseDetail2,
      banner: yanpan,
      children: [
        {
          title: "· 平台建设启动",
          text: "2019年，新疆交警总队按照“全范围覆盖、全过程记录、全数据监督”理念，建设公安交管大数据研判平台。用大数据的方法对业务数据进行融合分析、深度挖掘，结合Neo4j知识图谱技术、数据融合分析等技术，建立169+风险识别算法模型，将业务中存在的风险进行实时化、智能化、可视化、流程闭环化的监督管理。",
        },
        {
          title: "· 平台上线运行",
          text: "上线运行以来，接入公安六合一业务数据81亿条（2744.7G）、集成指挥数据96亿条（2056G）、交通安全综合服务（12123系统）业务数据59万条（187G）、农村道路系统数据48万条（156G）。经过平台的监测和分析，仅2月便产生业务预警超15万条，异常业务通报45次，异常部级业务通报量较同期下降49%，风险数量同比下降57%，对买分卖分、事故碰瓷等风险行为形成了严管高压态势，异常业务得到了有效监管。",
        },
      ],
    },
    {
      name: "“贵易行”小程序",
      img: caseDetail3,
      banner: guiyixing,
      children: [
        {
          title: "· 小程序上线公测",
          text: "2023年1月10日，由贵阳市交通委员会、贵阳市大数据发展管理局联合牵头推进的“数智贵阳—贵易行”微信小程序正式上线公测。立足于现有城市交通资源，对交通信息进行采集、分析、挖掘、发布，打造全方位、一站式的公众出行信息服务，为用户提供包括实时路况、实时公交、出租车空车分布、网上汽车票查询预订、出行规划、交通客服以及交通资讯在内的出行前与出行途中的全程交通信息服务，用户足不出户即可了解城市交通动态，科学规划出行方案。",
        },
        {
          title: "· 小程序上线运行",
          text: "上线运行以来，每日平均访问人数达到20000+，每次平均打开次数30000+，访问页面数达到60000+。并通过接入公交车数据、共享电单车数据、出租车数据、地铁数据、驾培数据、维修数据、停车场数据、路况数据（最好是有具体的数据量），充分利用微信平台快捷、便利、广泛的传播特点，逐步构建综合交通便民服务出行体系，更好地服务广大市民群众。",
        },
      ],
    },
    {
      name: "贵阳市共享电单车安全监管平台",
      img: caseDetail4,
      banner: diandanche,
      children: [
        {
          title: "· 小程序上线公测",
          text: "2022年，针对共享电单车乱停乱放、超区超量投放、僵尸车、故障车等问题，贵阳市上线运行共享电单车安全监管平台。通过对美团、青桔、小呗3家共享电单车企业数据的接入、治理、挖掘和分析，建立了车辆管理、车企管理、仓库管理、调度车辆、骑行人管理、投诉信息共6个数据资源库，构建预警模型算法21个、业务分析指标11个、考核指标25条，完成了对全市7.5万辆共享电单车的监管并预警。",
        },
        {
          title: "· 小程序上线运行",
          text: "上线以来，日违规处置量同比下降86.37%。",
        },
      ],
    },
    {
      name: "贵阳市城市智慧交通平台",
      img: caseDetail5,
      banner: zhihuijiaotong,
      children: [
        {
          title: "",
          text: (
            <div>
              2020年，贵阳市城市智慧交通平台在贵阳市上线运行，接入了全市公交、出租、网约车、停车场、航空、铁路、汽车维修、旅游景区等10+行业管理部门、40+业务系统数据，累计接入数据超过734.3亿条，构建了全市综合交通的数据中心。
              <br />
              通过大数据服务团队对数据的挖掘、分析，结合13类国家标准、省级标准和其他标准，以及7600条通用标准，构建了140个小类，共包含455项指标的交通行业指标体系，输出了120条元数据标准，涵盖了贵阳市重点场站客流分析、节假日交通流量预测、非法营运车辆监管、地面公交运等15个行业领域。
              <br />
              2022年，大数据服务团队为市交委提供12份客运分析报告，16份节假日分析报告、2份景区出行报告、2份重大活动出行报告并形成对应的节假日、景区和重大活动出行指南，6份交通组织优化报告并提出道路交叉口拥堵问题优化措施，1份错峰出行分析报告并提出错峰上学放学建议，1份公交地铁接驳分析报告用于评估地铁线路开通对沿线公交客运的影响从而进行公交线路的调整优化，1份路网运行分析报告和1份运输效力报告综合体现贵阳的道路运输情况共40+份分析报告。
              <br />
              同时，结合自身专业优势，研发并落地非法营运车辆识别、出租车异常聚集识别、公交/校车线路规划、节假日流量预测、道路拥堵指数计算等算法模型，为重要场站运力调度、重大节假日人车流量预测、路网运输效力等业务监管、分析提供保障，为构建智慧交通城市保驾护航。
            </div>
          ),
        },
      ],
    },
    {
      name: "贵阳市交管数据中心",
      img: caseDetail6,
      banner: jiaoguan,
      children: [
        {
          title: "",
          text: (
            <div>
              2015年， 某市交管数据中心在贵阳贵安上线运行，通过对公安交通管理综合应用平台、公安交通集成指挥平台、某区智能交通综合管控平台等平台的数据汇聚融合，完成对900多亿条卡口数据、5亿多条违法及相关数据、5.9亿多条业务办理数据、0.104亿多条事故及相关数据、0.82亿多条驾考及相关业务数据汇聚、治理、分析、存储和共享，打破数据孤岛，形成支撑某市交警实战化应用的统一交通数据资源管理中心。
              <br />
              上线以来，某市交管数据中心完成每日违法量、违法处理量、重点违法量、事故数量、每日新增车辆注册数，每日驾驶证领取数、每日进城车辆总数、每日出城车辆总数等5大类数据情况的统计分析，建立了车档类、驾档类、停车类等10+类数据主题库，实现了交管数据的统一集中管理、统一共享、统一治理。
            </div>
          ),
        },
      ],
    },
    {
      name: "新疆交警总队公安交管大数据研判平台",
      img: caseDetail7,
      banner: yanpan2,
      children: [
        {
          title: "",
          text: (
            <div>
              2019年，某公安交管大数据研判平台上线运行，平台完成了对公安网、视频专网、互联网、档案影像化系统等多往数据的梳理融合，并接入六合一业务数据71亿条（2344.7G）、集成指挥数据96亿条（2056G）、交通安全综合服务（12123系统）业务数据2194万条（187G）、农村道路系统数据48万条（156G），完成12个业务系统，3608张数据表的数据接入和标准化治理。
              <br />
              经过大数据团队的深入挖掘、分析，研发并落地了169个创新业务监管点，支持对机动车、驾驶证、驾考、违法、事故、执法设备六个方面所存在的风险应为进行监管，对例如买分卖分、事故碰瓷等风险行为形成了严管高压态势，异常业务得到了有效监管。
              <br />
              同时，联合该交警总队，共同研发了“重点车辆‘放大镜’，降低事故风险”模型，并荣获公安部全国建模大赛优秀奖。该模型通过对卡口数过车数据、手机信令数据等多数据的分析，实时筛选两客一危 、校车、农村面包车、重型货车、重型挂车等重点车辆，为监管部门精准、高效、及时处理提供支撑。
              <br />
              平台上线2个月，产生预警数据15万条+，完成业务数据分析3000万条，诊断异常指标253条，通报异常业务39次，异常业务通报同比下降49.37%。
            </div>
          ),
        },
      ],
    },
  ];
  return dataArr[checked] ? (
    <div className={styles.container}>
      <div className={styles.banner}>
        <img src={dataArr[checked]?.banner} />
      </div>
      <div className={styles.contentBox}>
        <div className={styles.left}>
          <img src={dataArr[checked].img} style={{marginRight:checked==3&&'150px',width:checked!=3&&'650px'}}/>
        </div>
        <div className={styles.right}>
          <div className={styles.titleBox}>{dataArr[checked].name}</div>
          <div className={styles.textBox}>
            {dataArr[checked].children.map((v, i) => (
              <div className={styles.textItem} key={i}>
                <div className={styles.title}>{v.title}</div>
                <div className={styles.text}>{v.text}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className={styles.empty}>
      <Empty description={false} />
    </div>
  );
};

export default Home;
