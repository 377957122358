import React, { useEffect, useState } from "react";
import { Badge, Statistic } from "antd";
import CountUp from "react-countup";
import classnames from "classnames";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { ZcBanner } from "@/components";
import styles from "./index.module.scss";
import TitleBox from "./components/TitleBox";
import tabbar1 from "@/assets/home/tabbar1.png";
import tabbar2 from "@/assets/home/tabbar2.png";
import tabbar3 from "@/assets/home/tabbar3.png";
import nextArrow from "@/assets/home/nextArrow.png";
import prevArrow from "@/assets/home/prevArrow.png";
import tocc from "@/assets/home/tocc.png";
import yanpan from "@/assets/home/yanpan.png";
import jgd from "@/assets/home/jgd.png";
import data from "@/assets/home/data.png";

import banner1 from "@/assets/home/banner1.jpg";
import banner2 from "@/assets/home/banner2.jpg";
import banner3 from "@/assets/home/banner3.jpg";
import banner4 from "@/assets/home/banner4.jpg";
import banner5 from "@/assets/home/banner5.jpg";

import dataSerTwo from "@/assets/home/dataSerTwo.png";
import solutionBanner1 from "@/assets/home/solutionBanner1.png";
import solutionBanner3 from "@/assets/home/solutionBanner3.png";
import solutionBanner2 from "@/assets/home/solutionBanner2.png";
import solutionBanner4 from "@/assets/home/solutionBanner4.png";
import cooperationBg from "@/assets/home/cooperationBg.png";
import cooperationIcon from "@/assets/home/cooperationIcon.png";
import cooperationIcon1 from "@/assets/home/cooperationIcon1.png";
import school1 from "@/assets/home/qinghua.png";
import school2 from "@/assets/home/jiaoda.png";
import school3 from "@/assets/home/changan.png";
import school4 from "@/assets/home/changshaligong.png";
import school5 from "@/assets/home/guizhou.png";
import school6 from "@/assets/home/guizhouligong.png";
import school7 from "@/assets/home/guizhoujiaotong.png";
import caseBg1 from "@/assets/home/caseBg1.png";
import caseBg2 from "@/assets/home/caseBg2.png";
import caseBg3 from "@/assets/home/caseBg3.png";
import caseBg4 from "@/assets/home/caseBg4.png";
import {useScroll} from "@/utils";
// 首页
const Home = (props) => {
  const { history } = props;
  const [checkedTab, setCheckedTab] = useState(0);
  const [productSlide, setProductSlide] = useState(0);
  const [bool, setBool] = useState(false);
  const list = [
    {
      title: "激活数据价值   引领交通创新",
      content: "—— 智诚科技，专业的交通大数据服务提供商",
      imgUrl: banner1,
      linkList: [{ title: "查看详情", url: "/aboutUs" }],
    },
    {
      title: "营运车辆大数据安全监管平台",
      content:
        "面向“两客一危”、公交车、出租网约车、共享电单车等营运车辆安全管理，提供“全范围覆盖、全过程记录、全数据监管”的风险隐患监测能力和全流程闭环的处理能力，提升道路运输安全水平。",
      imgUrl: banner2,
      // color: "#0F6FFF",
      fontSize: "24px",
      linkList: [{ title: "查看详情", url: "/coreProduct/vehicle" }],
    },
    {
      title: "交通管理大数据研判分析平台",
      content:
        "面向交通管理实现“全业务监管”，通过建立机动车、驾驶员、违法、事故、驾考等多个场景风险监管模型，提供高效的数据分析研判工具，提升交通管理风险研判效率。",
      imgUrl: banner3,
      fontSize: "24px",
      linkList: [{ title: "查看详情", url: "/coreProduct/judge" }],
    },
    {
      title: "大数据基础平台",
      content:
        "自主研发大数据管理平台，覆盖数据资源管理、数据质量运维、数据可视化、数据分析挖掘四大产品能力，提供交通运输和交通管理两大数据资源管理服务。",
      imgUrl: banner4,
      fontSize: "24px",
      linkList: [{ title: "查看详情", url: "/coreProduct/data" }],
    },
    {
      title: "智慧交通平台",
      content:
        "面向交通运输行业，为政府、企业提供营运车辆安全监管、综合交通运行监测、交通事件应急指挥、综合规划决策能力，为公众提供交通出行服务，实现城市交通大数据的综合应用。",
      imgUrl: banner5,
      // color: "#0F6FFF",.
      fontSize: "24px",
      linkList: [{ title: "查看详情", url: "/coreProduct/tocc" }],
    },
  ];
  // 初始加载
  useEffect(() => {}, []);
  const productArr = [
    {
      name: "交通运输",
      img: tabbar1,
      introduce:
        "面向交通运输行业，为政府、企业提供营运车辆安全监管、综合交通运行监测、交通事件应急指挥、综合规划决策能力，为公众提供交通出行服务，实现城市交通大数据的综合应用。",
      subChildren: [
        {
          name: "营运车辆大数据安全监管平台",
          imgUrl: jgd,
          content:
            "面向“两客一危”、公交车、出租网约车、共享电单车等营运车辆安全管理，提供“全范围覆盖、全过程记录、全数据监管”的风险隐患监测能力和全流程闭环的处理能力，提升道路运输安全水平。",
          link: "/coreProduct/vehicle",
        },
        {
          name: "智慧交通平台（TOCC）",
          imgUrl: tocc,
          content:
            "面向交通运输行业，为政府、企业提供营运车辆安全监管、综合交通运行监测、交通事件应急指挥、综合规划决策能力，为公众提供交通出行服务，实现城市交通大数据的综合应用。",
          link: "/coreProduct/tocc",
        },
      ],
    },
    {
      name: "交通管理",
      img: tabbar2,
      introduce:
        "面向交通管理提供基于大数据、人工智能技术的智能识别、风险预判、综合预警能力，解决风险识别耗时长、不精准、效率低的问题，实现交通管理风险预判的全流程闭环应用。",
      subChildren: [
        {
          name: "交通管理大数据研判分析平台",
          imgUrl: yanpan,
          content:
            "面向交通管理实现“全业务监管”，通过建立机动车、驾驶员、违法、事故、驾考等多个场景风险监管模型，提供高效的数据分析研判工具，提升交通管理风险研判效率。",
          link: "/coreProduct/judge",
        },
      ],
    },
    {
      name: "数据管理",
      img: tabbar3,
      introduce:
        "围绕交通领域数据资源的采集、治理、分析、挖掘、共享、开放，自主研发大数据管理平台，统一数据接入标准，构建数据一体化服务链路，支撑交通运输和交通管理两大产品线的数据化、智能化应用。",
      subChildren: [
        {
          name: "大数据基础平台",
          imgUrl: data,
          content:
            "自主研发大数据管理平台，覆盖数据资源管理、数据质量运维、数据可视化、数据分析挖掘四大产品能力，提供交通运输和交通管理两大数据资源管理服务。",
          link: "/coreProduct/data",
        },
      ],
    },
  ];
  // 数据服务
  const dataSerArr = [
    [
      { num: 21, isAdd: false, detail: "对接21个行业" },
      { num: 300, isAdd: true, detail: "沉淀300+张表" },
      { num: 2700, isAdd: true, detail: "梳理2700+数据资源项" },
      { num: 3000, isAdd: true, detail: "管理近3000万条数据" },
    ],
    [
      { num: 27, isAdd: false, detail: "掌握27个数据类别" },
      { num: 20, isAdd: true, detail: "研发20+算法模型" },
      { num: 7, isAdd: false, detail: "联合7所高校" },
      { num: 100, isAdd: true, detail: "拥有100+数据人才" },
    ],
  ];
  // 成功案例
  const caseArr = [
    {
      name: "贵阳市智慧交通平台（TOCC）",
      detail:
        "2020年，贵阳市交通委员会全面启动贵阳市智慧交通平台的建设，按“一中心、四平台、五项应用”的整体架构完成了数据平台和各类功能模块的建设，实现了交通领域人、车、路、行为等大数据资源全面汇聚和全市交通运行状况的实时监测与分析，推动全市交通大数据资源的整合与纵横向开放共享。平台上线后，接入全市公交、出租、网约车、停车场、航空、铁路、汽车维修、手机信令、轨道交通、电警卡口、两客一危GPS、联网售票、运政、机场大巴、旅游景区等10多个行业管理部门、40多业务系统，累计734.3亿条数据。通过对数据的分析和深入挖掘，实现对贵阳市道路通行指数、场站客流情况、公交出租态势实时分析监测。同时，提供道路拥堵指数计算、节假日交通流量预测、重要场站客运运力调度、非法运营车识别等数据分析服务，帮助交通管理部门实时监测城市交通运行情况，并及时发现问题、解决问题，真正让数据的价值和应用辅助决策。",
      img: caseBg1,
      link: "/home/caseDetail?sort=0",
    },
    {
      name: "浙江省营运车第三方检测服务平台",
      detail:
        "2019年，浙江省交通厅建设浙江省营运车第三方安全监测服务平台，平台以“两客一危”车辆监测为主，逐步覆盖监测全省营运车辆。通过获取实时、海量、多维的“人、车、企、客、货、路”数据，敏锐地感知车辆安全风险，精准地优化企业的运营管理，提升政府监管效率，降低政府监管压力，有效指导相关企业进行优化整改。平台于2019年5月上线，接入1373家“两客一危”企业，车辆1.9万余辆，驾驶员6千余人。针对驾驶人、设备、车辆，按照行为异常、疲劳驾驶、超速驾驶、超时驾驶、凌晨禁行、设备异常、行驶异常等7大类型共计监测到150万余起风险。上线运行以来，平台监管到的重大风险环比下降9.32%，风险处置率上升到76.9%；车辆安全行驶里程达1343万公里，安全行驶时长达38.1万小时，有力保障了全省道路运输安全的良好态势。",
      img: caseBg2,
      link: "/home/caseDetail?sort=1",
    },
    {
      name: "新疆交警总队公安交管大数据研判平台",
      detail:
        "2019年，新疆交警总队按照“全范围覆盖、全过程记录、全数据监督”理念，建设公安交管大数据研判平台。用大数据的方法对业务数据进行融合分析、深度挖掘，结合Neo4j知识图谱技术、数据融合分析等技术，建立169+风险识别算法模型，将业务中存在的风险进行实时化、智能化、可视化、流程闭环化的监督管理。上线运行以来，接入公安六合一业务数据81亿条（2744.7G）、集成指挥数据96亿条（2056G）、交通安全综合服务（12123系统）业务数据59万条（187G）、农村道路系统数据48万条（156G）。经过平台的监测和分析，仅2月便产生业务预警超15万条，异常业务通报45次，异常部级业务通报量较同期下降49%，风险数量同比下降57%，对买分卖分、事故碰瓷等风险行为形成了严管高压态势，异常业务得到了有效监管。",
      img: caseBg3,
      link: "/home/caseDetail?sort=2",
    },
    {
      name: "“贵易行”小程序",
      detail:
        "2023年1月10日，由贵阳市交通委员会、贵阳市大数据发展管理局联合牵头推进的“数智贵阳—贵易行”微信小程序正式上线公测。立足于现有城市交通资源，对交通信息进行采集、分析、挖掘、发布，打造全方位、一站式的公众出行信息服务，为用户提供包括实时路况、实时公交、出租车空车分布、网上汽车票查询预订、出行规划、交通客服以及交通资讯在内的出行前与出行途中的全程交通信息服务，用户足不出户即可了解城市交通动态，科学规划出行方案。上线运行以来，每日平均访问人数达到20000+，每次平均打开次数30000+，访问页面数达到60000+。并通过接入公交车数据、共享电单车数据、出租车数据、地铁数据、驾培数据、维修数据、停车场数据、路况数据（最好是有具体的数据量），充分利用微信平台快捷、便利、广泛的传播特点，逐步构建综合交通便民服务出行体系，更好地服务广大市民群众。",
      img: caseBg4,
      link: "/home/caseDetail?sort=3",
    },
  ];
  // 解决方案
  const solutionArr = [
    {
      name: [`智慧交通平台大数据`, "解决方案"],
      detail:
        "通过建设一中心、N应用、一平台的方式，为交通运输部门构建城市级智慧交通平台，完善优化对外信息服务能力，满足公众出行的迫切需要。",
      img: solutionBanner1,
      link: "/solution/city",
    },
    {
      name: ["“两客一危”安全监管", "大数据解决方案"],
      detail:
        "通过对两客一危行业进行“全范围覆盖、全过程记录、全数据监管”，为交通运输部门对两客一危业务风险隐患进行监管预防提供数据支撑，让事故发生率无限趋近于零，让道路更安全。",
      img: solutionBanner2,
      link: "/solution/lkyw",
    },
    {
      name: ["“共享电单车”安全监管", "大数据解决方案"],
      detail:
        "通过对共享电单车行业进行“数据接入统一管理、平台自动告警辅助监管、政企数据共享，多部门协同监管”，协助政府主管部门解决骑行人乱停乱放、企业超量投放及僵尸车辆问题进行监管。",
      img: solutionBanner3,
      link: "/solution/scooter",
    },
    {
      name: ["交管全业务监管大数据", "解决方案"],
      detail:
        "通过建立机动车业务监管、违法业务监管、驾考业务监管、事故业务监管、驾驶证业务监管、剧毒运输品业务监管等全业务场景风险监管模型，面向交通管理部门提供高效的数据分析研判工具，有效指导业务开展，将业务中存在的风险进行实时化、智能化、可视化、闭环化的监督管理。",
      img: solutionBanner4,
      link: "/solution/traffic",
    },
  ];
  function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <img
        src={nextArrow}
        className={className}
        style={style}
        onClick={onClick}
      />
    );
  }

  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <img
        src={prevArrow}
        className={className}
        style={style}
        onClick={onClick}
      />
    );
  }

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (current, next) => setProductSlide(next),
  };
  const solutionSettings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };
  const caseSettings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 2,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
  };
  const handleGo = (href) => {
    history.push(href);
  };
  const formatter = (value) => <CountUp start={0} end={value} separator=""/>;
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const handleScroll = () => {
    if (document.documentElement.scrollTop >= 1450) {
      setBool(true);
    }
  };
  // 滚动加载模块，后续深入优化
  const { currentLevel } = useScroll({grade: 6});

  return (
    <div className={styles.homeBox}>
      <div className={styles.banner}>
        <ZcBanner list={list} history={history} />
      </div>
      {/* 产品介绍 */}
      {
        currentLevel > 1 &&
        <div className={styles.product}>
          <TitleBox
              name="产品与服务"
              conArr={[
                "围绕大交通领域，构建“一个数据平台，两大产品线”的交通领域综合服务能力。",
              ]}
              style={{ height: "292px" }}
          />
          <div className={styles.productContent}>
            <div className={styles.tabbarBox}>
              {productArr.map((v, i) => (
                  <div
                      className={classnames({
                        [styles.tabbar]: true,
                        [styles.checkedTab]: checkedTab === i,
                      })}
                      key={i}
                      onClick={() => setCheckedTab(i)}
                  >
                    <img src={v.img} />
                    <div className={styles.nameBox}>
                      <span className={styles.name}>{v.name}</span>
                      <div className={styles.tagBox}>
                        {v.subChildren.map((val, index) => (
                            <div className={styles.tag} key={index}>
                              {val.name}
                            </div>
                        ))}
                      </div>
                    </div>
                  </div>
              ))}
            </div>
            <div className={styles.productSwiper}>
              <div className={styles.introduce}>
                <div className={styles.title}>{productArr[checkedTab].name}</div>
                <div>{productArr[checkedTab].introduce}</div>
              </div>
              <div className={styles.swiper}>
                {productArr[checkedTab].subChildren.length > 1 ? (
                    <>
                      <Slider
                          {...settings}
                          className="swiper-container"
                          style={{ with: "100%" }}
                      >
                        {productArr[checkedTab].subChildren.map((item, index) => {
                          return (
                              <div key={index}>
                                <div
                                    style={{ backgroundImage: `url(${item.imgUrl})` }}
                                    className={styles.bannerBox}
                                ></div>
                              </div>
                          );
                        })}
                      </Slider>
                      <div className={styles.positionBox}>
                        <div className={styles.name}>
                          {productArr[checkedTab].subChildren[productSlide]?.name}
                        </div>
                        <div className={styles.content}>
                          {
                            productArr[checkedTab].subChildren[productSlide]
                                ?.content
                          }
                        </div>
                        <button
                            onClick={() =>
                                handleGo(
                                    productArr[checkedTab].subChildren[productSlide]?.link
                                )
                            }
                        >
                          查看产品详情
                        </button>
                      </div>
                    </>
                ) : (
                    <>
                      {productArr[checkedTab].subChildren.map((item, index) => {
                        return (
                            <div key={index}>
                              <div
                                  style={{ backgroundImage: `url(${item.imgUrl})` }}
                                  className={styles.bannerBox}
                              ></div>
                            </div>
                        );
                      })}
                      <div className={styles.positionBox}>
                        <div className={styles.name}>
                          {productArr[checkedTab].subChildren[0]?.name}
                        </div>
                        <div className={styles.content}>
                          {productArr[checkedTab].subChildren[0]?.content}
                        </div>
                        <button
                            onClick={() =>
                                handleGo(productArr[checkedTab].subChildren[0]?.link)
                            }
                        >
                          查看产品详情
                        </button>
                      </div>
                    </>
                )}
              </div>
            </div>
          </div>
        </div>
      }

      {/* 数据服务 */}
      {
        currentLevel > 2 &&
        <div className={styles.dataServices}>
          <TitleBox
              name="数据服务"
              conArr={[
                "以数据为核心，提供数据采集、治理、分析、计算和创新应用的综合能力。",
              ]}
              style={{ height: "258px" }}
          />
          <div className={styles.dataNumBox}>
            {dataSerArr.map((v, i) => (
                <div className={styles.numItem} key={i}>
                  {v.map((val, index) => (
                      <div className={styles.numBox} key={index}>
                        <div className={styles.num}>
                          <Statistic
                              value={bool?val.num:0}
                              formatter={formatter}
                              suffix={val.isAdd && "+"}
                          />
                        </div>
                        <div className={styles.text}>{val.detail}</div>
                      </div>
                  ))}
                </div>
            ))}
          </div>
          {/* <img src={dataSerOne} style={{ height: "440px" }} /> */}
          <img src={dataSerTwo} />
        </div>
      }

      {/* 解决方案 */}
      {
        currentLevel > 3 &&
        <div className={styles.solution}>
          <TitleBox
              name="解决方案"
              conArr={[
                "行业领军企业的数据分析与数据智能决策，实现数字化转型行业全覆盖。数据治理与数据分析全方位解决方案，完美适配数据，",
                "应用全场景，专属数据解决方案与服务，伴随客户持续成长。",
              ]}
              style={{ height: "276px" }}
          />
          <div className={styles.swiperBox}>
            <Slider
                {...solutionSettings}
                className="swiper-container"
                style={{ with: "100%" }}
            >
              {solutionArr.map((item, index) => {
                return (
                    <div key={index}>
                      <div key={index} className={styles.bannerBox}>
                        <div className={styles.bannerTitle}>
                          <img src={item.img} />
                          <div className={styles.title}>
                            {item.name[0]}
                            <br />
                            {item.name[1]}
                          </div>
                        </div>
                        <div className={styles.bannerdetail}>
                          <div className={styles.detail}>{item.detail}</div>
                          <a
                              className={styles.link}
                              onClick={() => handleGo(item?.link)}
                          >
                            {"查看详情  >"}
                          </a>
                        </div>
                      </div>
                    </div>
                );
              })}
            </Slider>
            {/* 虚化效果 */}
            <div className={styles.left}></div>
            <div className={styles.right}></div>
          </div>
        </div>
      }

      {/* 高校合作 */}
      {
        currentLevel > 4 &&
        <div className={styles.cooperation}>
          <TitleBox
              name="高校合作"
              conArr={[
                "深耕交通大数据，深化校企合作！贵州智诚科技有限公司始终坚持自主研发和产学研结合的道路，",
                "持续开启探索教学、人才培养、技术研发等校企合作新模式，不断拓展合作的广度和深度。",
              ]}
              style={{ height: "292px" }}
          />
          <div className={styles.contentBox}>
            <img src={cooperationBg} />
            <div className={styles.detail}>
              <div className={styles.detailItem1}>
                <div>
                  <img src={cooperationIcon} />
                  <div className={styles.blue}>合作高校产学合作实践</div>
                </div>
                <div>
                  <img src={cooperationIcon1} />
                  <div className={styles.blue}>合作多项关键技术</div>
                </div>
              </div>
              <div className={styles.detailItem2}>
                <div className={styles.flex}>
                  <div>
                    <img src={school1} />
                    清华大学
                  </div>
                  <div>
                    <img src={school2} />
                    上海交通大学
                  </div>
                  <div>
                    <img src={school3} />
                    长安大学
                  </div>
                </div>
                <div className={styles.line}></div>
                <div className={styles.flex}>
                  <div>
                    <img src={school4} />
                    长沙理工大学
                  </div>
                  <div>
                    <img src={school5} />
                    贵州大学
                  </div>
                  <div>
                    <img src={school6} />
                    贵州理工学院
                  </div>
                  <div>
                    <img src={school7} />
                    贵州交通职业技术学院
                  </div>
                </div>
              </div>
              <div className={styles.detailItem3}>
                <Badge
                    color="#565759"
                    text={
                      <div className={styles.text}>
                        2019-12-10 智诚科技与贵州理工学院大数据学院签订校企合作协议
                        <a
                            className={styles.link}
                            onClick={() =>
                                window.open(
                                    "https://mp.weixin.qq.com/s/8lKWry_4TFilHWhMeh3cIA"
                                )
                            }
                        >
                          {"详情  >"}
                        </a>
                      </div>
                    }
                />
                <Badge
                    color="#565759"
                    text={
                      <div className={styles.text}>
                        2020-11-03
                        贵州智诚科技董事长徐桂林获清华-贵州大数据研究生实践教育基地2019级企业导师聘书
                        <a
                            className={styles.link}
                            onClick={() =>
                                window.open(
                                    "https://mp.weixin.qq.com/s/vFVXlZKJxhKcyacg94aaBQ"
                                )
                            }
                        >
                          {"详情  >"}
                        </a>
                      </div>
                    }
                />
                <Badge
                    color="#565759"
                    text={
                      <div className={styles.text}>
                        2022-08-30
                        贵州智诚科技董事长徐桂林获“上海交大-贵州专业学位研究生联合培养基地”行业导师聘书
                        <a
                            className={styles.link}
                            onClick={() =>
                                window.open(
                                    "https://mp.weixin.qq.com/s/DAvApOp1--3FpDhgWovQdA"
                                )
                            }
                        >
                          {"详情  >"}
                        </a>
                      </div>
                    }
                />
                <Badge
                    color="#565759"
                    text={
                      <div className={styles.text}>
                        2019-06-27 智诚科技赴长沙理工大学校企合作
                        <a
                            className={styles.link}
                            onClick={() =>
                                window.open(
                                    "https://mp.weixin.qq.com/s/_ET8xoA1CHFOjTSazPuJ1w"
                                )
                            }
                        >
                          {"详情  >"}
                        </a>
                      </div>
                    }
                />
              </div>
            </div>
            <div className={styles.left}></div>
            <div className={styles.right}></div>
          </div>
        </div>
      }

      {/* 成功案例 */}
      {
        currentLevel > 5 &&
        <div className={styles.case}>
          <TitleBox
              name="成功案例"
              conArr={[
                "通过大数据+交通的深度融合，构建联通政府、企业及公众三端数据融合的全域交通产业生态。",
              ]}
              style={{ height: "256px" }}
          />
          <div className={styles.detail}>
            <div
                style={{ background: `url(${caseArr[0]?.img})` }}
                className={styles.leftBox}
            >
              <div className={styles.message}>
                <div className={styles.title}>{caseArr[0]?.name}</div>
                <div className={styles.text}>{caseArr[0]?.detail}</div>
                <a
                    className={styles.link}
                    onClick={() => handleGo(caseArr[0]?.link)}
                >
                  {"详情  >"}
                </a>
              </div>
            </div>
            <div className={styles.rightBox}>
              <Slider
                  {...caseSettings}
                  className="swiper-container"
                  style={{ with: "100%" }}
              >
                {caseArr.splice(1).map((item, index) => {
                  return (
                      <div key={index}>
                        <div className={styles.bannerBox}>
                          <div className={styles.bannerTitle}>
                            <img src={item.img} />
                          </div>
                          <div className={styles.bannerdetail}>
                            <div className={styles.title}>{item.name}</div>
                            <div className={styles.text}>{item.detail}</div>
                            <a
                                className={styles.link}
                                onClick={() => handleGo(item.link)}
                            >
                              {"详情  >"}
                            </a>
                          </div>
                        </div>
                      </div>
                  );
                })}
              </Slider>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default Home;
