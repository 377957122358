/**
 * 动画组件
 * */

// 容器组件
import Animation1 from './Animation1';
import Animation2 from './Animation2';
import Animation3 from './Animation3';
import Animation4 from './Animation4';
import Animation5 from './Animation5';

const Animation = {
    Animation1,
    Animation2,
    Animation3,
    Animation4,
    Animation5
}

export default Animation;