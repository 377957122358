import React, { useEffect, useState, useRef } from "react";
import { Anchor, Animation } from '@/components';
import AppScene from '../../CoreProduct/components/AppScene';
import { Timeline, Tabs, Tooltip } from 'antd';
import classnames from "classnames";
import bg from "@/assets/icons/bg.png";
import reel from "@/assets/icons/reel.png";
import challenge from "@/assets/icons/challenge.png";
import rj_result_city1 from "@/assets/icons/rj_result_city1.png";
import rj_result_city2 from "@/assets/icons/rj_result_city2.png";
import rj_result_city3 from "@/assets/icons/rj_result_city3.png";
import rj_result_city4 from "@/assets/icons/rj_result_city4.png";
import rj_result_city5 from "@/assets/icons/rj_result_city5.png";
import program1 from "@/assets/icons/program11.png";
import program2 from "@/assets/icons/program6.png";
import program3 from "@/assets/icons/program12.png";
import program4 from "@/assets/icons/program13.png";
import solution1 from "@/assets/icons/solution1.png";
import solution2 from "@/assets/icons/solution2.png";
import solution3 from "@/assets/icons/solution3.png";
import solution4 from "@/assets/icons/solution4.png";
import resolution1 from "@/assets/icons/resolution1.png";
import resolution2 from "@/assets/icons/resolution2.png";
import resolution3 from "@/assets/icons/resolution3.png";
import resolution4 from "@/assets/icons/resolution4.png";
import suc1 from "@/assets/icons/suc1.png";
import suc2 from "@/assets/icons/suc4.png";
import styles from "../index.module.scss";

const { Animation1, Animation2, Animation3, Animation4, Animation5 } = Animation;

// 首页
const Home = (props) => {
  const { history } = props;
  const styleRef1 = useRef();
  const styleRef2 = useRef();
  const styleRef3 = useRef();
  const styleRef4 = useRef();

  const [checkedTab, setCheckedTab] = useState(0);

  // 初始加载
  useEffect(() => { }, []);

  const handleClick = (link) => {
    console.log(link);
  };
  const handleChange = (link) => {
    console.log(link);
  };
  const onChange = () => {

  }
  const sceneList = [
    {
      title: '营运车辆运输监管',
      img: rj_result_city1,
      content: '实现对“两客一危”，重货，网约车出租车，公交车的全业务监管。'
    },
    {
      title: '重点场站实时监测',
      img: rj_result_city2,
      content: '实现对高铁站、机场、公交枢纽、景区、商区重点场站的实时监测。'
    },
    {
      title: '交通事件指挥调度',
      img: rj_result_city3,
      content: '实现对重要场站运力调度、车辆精准引导的指挥调度。'
    },
    {
      title: '重大活动资源保障',
      img: rj_result_city4,
      content: '支撑对传统节假日、数博会等重大活动的资源保障。'
    },
    {
      title: '交通规划分析决策',
      img: rj_result_city5,
      content: '支撑对公交线路定制、公交线网优化的规划决策。'
    },
  ]

  return (
    <div className={styles.homeBox}>
      <div className={styles.banner}>
        <div className={styles.bannerBox} style={{ backgroundImage: `url(${solution1})` }}>
          <div className={styles.content}>智慧交通平台大数据解决方案</div>
        </div>
      </div>
      <div className={styles.product}>
        <Anchor onChange={handleChange} onClick={handleClick} data={[
          {
            key: 'part-1',
            href: '#part-1',
            title: '行业背景',
          },
          {
            key: 'part-2',
            href: '#part-2',
            title: '行业痛点',
          },
          {
            key: 'part-3',
            href: '#part-3',
            title: '解决路径',
          },
          {
            key: 'part-4',
            href: '#part-4',
            title: '应用场景',
          },
          {
            key: 'part-5',
            href: '#part-5',
            title: '方案优势',
          },
          {
            key: 'part-6',
            href: '#part-6',
            title: '成功案例',
          },
        ]} />
        <div id="part-1" className={styles.cardBox} style={{ backgroundImage: `url(${bg})` }}>
          <div className={styles.title}>行业背景</div>
          <div className={styles.timeLineBox}>
            <div className={styles.boxTitle}>
              <img src={reel}></img>
              <div className={styles.boxTitleContent}>政策依据</div>
            </div>
            <div className={styles.timeLine}>
              <Timeline mode='left'>
                <Timeline.Item label="2021">
                  <div className={styles.timeLineContent}>2021年9月，交通运输部引发<span>《交通运输领域新型基础设施建设行动方案（2021年-2025年）》</span>，提出加快交通运输信息化向数字化、网格化、智能化发展的具体举措。</div>
                </Timeline.Item>
                <Timeline.Item label="2019">
                  <div className={styles.timeLineContent}>2019年9月，颁布的<span>《交通强国建设纲要》</span>提出，要大力发展智慧交通、推进数据资源赋能交通发展、构建泛在先进的交通信息设施、构建综合交通大数据中心体系。</div>
                </Timeline.Item>
                <Timeline.Item label=""></Timeline.Item>
              </Timeline>
            </div>
          </div>
          <div className={styles.bottomLineBox}>
            <div className={styles.bottomLine}><span></span>国家为智慧交通提供了良好机遇，新一代新兴技术发展也为智慧交通提供了强大支撑；</div>
            <div className={styles.bottomLine}><span></span>智慧交通是解决现有交通问题的重要突破口，也是全面深化交通领域改个的重要手段；</div>
            <div className={styles.bottomLine}><span></span>利用城市各类分散的交通信息资源，完善优化对外信息服务能力，满足公众出行的迫切需要，让城市智慧交通平台的建设势在必行。</div>
          </div>
        </div>
        <div id="part-2" className={classnames({
              [styles.cardBox]: true,
              [styles.cardBoxHover]: true,
            })} style={{ paddingBottom: '48px', backgroundColor: '#FFFFFF' }}>
          <div className={styles.title}>行业痛点</div>
          <div className={styles.challengeBox}>
            <div className={styles.challenge} ref={styleRef1}>
              <div className={styles.animationIcon}><Animation1 element={styleRef1} /></div>
              <div className={styles.challengeContent}>信息系统烟囱林立，数据孤岛问题严重</div>
              <div className={styles.challengeContent2}>各地信息化系统建设缺乏统一规划，交通行业各层级部门大多通过国家和省市零散的要求以及自身发展和管理需求被动建设信息化系统，缺乏统筹，各系统无法衔接互用，数据资源分散，孤岛现象严重。</div>
            </div>
            <div className={styles.challenge} ref={styleRef2}>
              <div className={styles.animationIcon}><Animation5 element={styleRef2} /></div>
              <div className={styles.challengeContent}>道路运输监管分散，运输资源协调困难</div>
              <div className={styles.challengeContent2}>道路运输监管对象、监管指标不统一，没有完整的数据资源仓库、统一规范的数据模型或者指标体系，无法对现有交通业务提供根本的数据支撑，严重影响各业务委办局的业务流转，导致运力调度能力较弱。</div>
            </div>
            <div className={styles.challenge} ref={styleRef3}>
              <div className={styles.animationIcon}><Animation4 element={styleRef3} /></div>
              <div className={styles.challengeContent}>部门协调能力薄弱，应急指挥手段单一</div>
              <div className={styles.challengeContent2}>缺乏统一管理平台、大数据、AI智能分析手段，无法及时掌握路网饱和度、拥堵等实时情况，应急指挥决策滞后性严重，同时，各业务系统与平台建设之间的关系不明，各业务部门各自为政，不成体系，部门合作共享频率低，应急指挥保障手段不足。</div>
            </div>
          </div>
          <div className={classnames({
              [styles.challengeBox]: true,
            })} style={{ marginTop: 0 }}>
            <div className={styles.challenge} ref={styleRef4}>
              <div className={styles.animationIcon}><Animation3 element={styleRef4} /></div>
              <div className={styles.challengeContent}>数据价值未被激活，市民交通出行不便</div>
              <div className={styles.challengeContent2}>大数据分析挖掘能力不足，不能对数据进行全面深入挖掘，数字化分析内驱力不足，数据服务水平低，无法为市民出行提供出行信息查询、行业信息查询等实时服务。</div>
            </div>
            <div className={styles.challenge} style={{ opacity: 0, cursor: 'default' }}></div>
            <div className={styles.challenge}  style={{ opacity: 0, cursor: 'default' }}></div>
          </div>
        </div>
        <div id="part-3" className={styles.cardBox}>
          <div className={styles.title}>解决路径</div>
          <div className={styles.solveBox}>
            <div className={styles.left} style={{backgroundImage: `url(${resolution1})`}}></div>
            <div className={styles.right}>
              <div className={styles.line2}>统一汇聚全域数据</div>
              <div className={styles.line3} style={{ marginBottom: '10px' }}>融合汇聚城市基础信息、气象信息、城市交通信息、铁路机场信息等全域数据，打破数据壁垒。</div>
              <div className={styles.line2}>构建交通数据中心</div>
              <div className={styles.line3} style={{ marginBottom: '10px' }}>建立交通行业指标体系、数据集市，深化数据治理，提高数据质量，强化数据支撑能力。</div>
              <div className={styles.line2}>全面监管交通运输</div>
              <div className={styles.line3} style={{ marginBottom: '10px' }}>结合道路基础设施及监控设备数据，实现对全车网、全路网的全面监测，实时掌握道路运输情况、客流情况。</div>
              <div className={styles.line2}>联动部门应急指挥</div>
              <div className={styles.line3} style={{ marginBottom: '10px' }}>可视化展示交通隐患数据，打造综合交通应急指挥中心，保障应急事件及时响应，合理调度运力资源。</div>
              <div className={styles.line2}>完善交通出行服务</div>
              <div className={styles.line3}>构建交通信用小程序，及时发布道路拥堵情况等交通信息，提供出行服务、出行路径规划、行业信息等服务，方便市民出行。</div>
            </div>
          </div>
        </div>
        <AppScene id={'part-4'} data={sceneList} lightTheme style={{ backgroundColor: '#FFFFFF' }} />
        <div id="part-5" className={styles.cardBox}>
          <div className={styles.title}>方案优势</div>
          <div className={styles.programBox}>
            <div className={styles.programBoxItem} style={{ borderRight: '0', borderBottom: '0' }}>
              <img src={program1} />
              <div className={styles.programContent}>全面汇聚数据资源，充分挖掘数据价值</div>
              <div className={styles.contentHover}>接入城市路网、乡村道路网、高速公路网、轨道交通、地面公交、出租车、公共自行车、两客一危、市政设施、停车等全行业、全领域交通运行数据，加强多领域、跨领域数据应用与分析，发挥数据价值。</div>
            </div>
            <div className={styles.programBoxItem} style={{ borderBottom: '0' }}>
              <img src={program2} />
              <div className={styles.programContent}>促进部门功能衔接，深化部门统筹职能</div>
              <div className={styles.contentHover}>统一建设智慧平台，实现对交通基础设施、运载工具、客货流流的行业统一监管与指导，促进多部门信息互通共享，加强多部门沟通、协调，实现业务协同，增强交通运输系统的服务能力，强化监管部门职责。</div>
            </div>
            <div className={styles.programBoxItem} style={{ borderRight: '0' }}>
              <img src={program3} />
              <div className={styles.programContent}>实现行业精准管理，完善交通信息服务</div>
              <div className={styles.contentHover}>通过信息化平台的实时监测和数据分析，实现对交通行业的精准化、行业规划发展的科学化管理，满足群众多层次、多样化的出行需求，为出行者提供多样化、高品质的一体化信息服务。</div>
            </div>
            <div className={styles.programBoxItem}>
              <img src={program4} />
              <div className={styles.programContent}>推进数据共享开放，实现资源共建共享</div>
              <div className={styles.contentHover}>整合汇聚交通系统现有数据，统筹数据管理、数据共享，构建交通行业数据资源主题库，提供对外数据服务接口和数据服务，满足政府内部和外部信息共享交换的需求，实现数据资源共建共享。</div>
            </div>
          </div>
        </div>
        <div id="part-6" className={styles.cardBox} style={{ backgroundColor: '#FFFFFF' }}>
          <div className={styles.title}>成功案例</div>
          <div className={styles.sucBox}>
            <div className={styles.sucItem} onClick={() => history.push('/home/caseDetail?sort=0')}>
              <img src={suc1} />
              <div className={styles.sucContent}>贵阳市城市智慧交通平台</div>
              <div className={styles.message}>
                <div className={styles.title}><Tooltip title="贵阳市城市智慧交通平台">贵阳市城市智慧交通平台</Tooltip></div>
                <div className={styles.text}>2020年，贵阳市交通委员会全面启动贵阳市智慧交通平台的建设，按“一中心、四平台、五项应用”的整体架构完成了数据平台和各类功能模块的建设，实现了交通领域人、车、路、行为等大数据资源全面汇聚和全市交通运行状况的实时监测与分析，推动全市交通大数据资源的整合与纵横向开放共享。</div>
                <div className={styles.link}>
                  {"查看详情  >"}
                </div>
              </div>
            </div>
            <div className={styles.sucItem} onClick={() => history.push('/home/caseDetail?sort=3')}>
              <img src={suc2} />
              <div className={styles.sucContent}>“贵易行”小程序</div>
              <div className={styles.message}>
                <div className={styles.title}><Tooltip title="“贵易行”小程序">“贵易行”小程序</Tooltip></div>
                <div className={styles.text}>2023年1月10日，由贵阳市交通委员会、贵阳市大数据发展管理局联合牵头推进的“数智贵阳—贵易行”微信小程序正式上线公测。立足于现有城市交通资源，对交通信息进行采集、分析、挖掘、发布，打造全方位、一站式的公众出行信息服务，为用户提供包括实时路况、实时公交、出租车空车分布、网上汽车票查询预订、出行规划、交通客服以及交通资讯在内的出行前与出行途中的全程交通信息服务，用户足不出户即可了解城市交通动态，科学规划出行方案。</div>
                <div className={styles.link}>
                  {"查看详情  >"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
